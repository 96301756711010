import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, Grid, Button, Alert, Tabs, Tab } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import Navigation from "./navigation";
import Footer from "./components/footer/footer";
import CarouselText from "./components/carousel/caroutselText";
import BannerService from "../services/manager/bannerService";
import TagService from "../services/manager/tagService";
import BannerCarousel2 from "./components/carousel/carouselBanner2";
import ItemCarousel from "./components/item/carousel";
import ItemCard from "./components/item/itemCard";
import ShowMoreCard from "./components/item/showMoreCard";
import noImage from "../assets/no-image.png"

const iamgeUrl = "https://api.liquestore.com/assets/items/"

export default function HomePage2() {
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [banners, setBanners] = useState([]);
  const [tagSecondaryList, setTagSecondary] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [shownTagItem, setShownTagItem] = useState([]);
  const navigate = useNavigate();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const getAllBanner = async () => {
    try {
      const response = await BannerService.getAllBanner();
      if (response) {
        const data = response.data.outputSchema;
        setBanners(data);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list banners");
      activateError();
    }
  }

  const getTagByFlag = async (flag) => {
    try {
      const response = await TagService.getShownTagList(flag);
      if (response) {
        const data = response.data.tagModelList;
        return data.filter((tag) => tag.flag === flag);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  async function fetchItems() {
    try {
      const tagFiltered = tagSecondaryList.slice(0, 3);
      const shownData = await Promise.all(
        tagFiltered.map(async (tag) => {
          const responseItem = await CustomerService.getListItemPageable(
            1, 8,
            '',
            null,
            tag.id
          );
          return { ...tag, items: responseItem.data.itemList.slice(0, 12) };
        })
      );
      setShownTagItem(shownData);
    } catch (error) {
      console.error(error);
    }
  }

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  useEffect(() => {
    const getTag = async () => {
      setTagSecondary(await getTagByFlag("secondary"));
      setBrandList(await getTagByFlag("brand"));
    }

    getTag();
    getAllBanner();
  }, []);

  useEffect(() => {
    if (tagSecondaryList.length > 0) {
      fetchItems();
    }
  }, [tagSecondaryList])

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ paddingBottom: "50px", minHeight: "80vh"}}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        {/* {
          banners[0] ? (
            <Grid container marginTop={3} justifyContent="center">
              <Grid item xs={12}>
                <CarouselText texts={banners[0]?.runningText} />
              </Grid>
            </Grid>
          ) : null
        } */}

        {
          banners[1] ? (
            <Grid container marginTop={3} justifyContent="center">
              <Grid item xs={12}>
                <BannerCarousel2 images={banners[1]?.images || []} 
                  title={banners[1]?.title}
                  description={banners[1]?.description}
                  navigateTo={`/items/tag/${banners[1]?.tagSecondary.id}`} />
              </Grid>
            </Grid>
          ) : null
        }

        <Grid container spacing={1} marginTop={3}>
          <Grid item xs={6}>
            <Grid container rowGap={1}>
              {
                tagSecondaryList.slice(0, 2).map(tag => (
                  <Grid item key={tag.id} xs={12} onClick={() => navigate(`/items/tag/${tag.id}`)}
                    sx={{ 
                      width: "100%", 
                      height: "100px", 
                      backgroundColor: "black", 
                      borderRadius: "12px", 
                      position: "relative", 
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "end",
                      cursor: "pointer"
                    }}
                  >
                    <div className="image-overlay"></div>

                    <Typography variant="h5" color="white" margin="0 0 20px 20px">{tag.title}</Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container height="100%">
              <Grid item  
                sx={{ 
                  width: "100%", 
                  height: "100p%", 
                  backgroundColor: "black", 
                  borderRadius: "12px", 
                  position: "relative", 
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="image-overlay"></div>
                <div className="image-overlay start"></div>

                <Typography variant="h5" color="white">Register Now</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          shownTagItem[0] && (
            <ItemCarousel title={shownTagItem[0].title}>
              {
                shownTagItem[0].items.map(item => (
                  <ItemCard key={item.id} item={item} />
                ))
              }
              <ShowMoreCard onClick={() => navigate(`/items/tag/${shownTagItem[0].id}`)} />
            </ItemCarousel>
          )
        }

        {
          shownTagItem[1] && (
            <ItemCarousel title={shownTagItem[1].title}>
              {
                shownTagItem[1].items.map(item => (
                  <ItemCard key={item.id} item={item} />
                ))
              }
              <ShowMoreCard onClick={() => navigate(`/items/tag/${shownTagItem[1].id}`)} />
            </ItemCarousel>
          )
        }

        <ItemCarousel title="Belanja Berdasarkan Merek">
          {
            brandList.map(brand => (
              <Box style={{ 
                borderRadius: "12px",
                overflow: "hidden",
                margin: "10px"
               }}
                onClick={() => navigate(`/items/tag/${brand.id}`)} 
                key={brand.id}
              >
                <img
                  src={brand.images == null ? noImage : `${imageUrl}${brand.images[0]}`}
                  alt={brand.name}
                  style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "contain" }}
                ></img>
              </Box>
            ))
          }
        </ItemCarousel>

        {
          shownTagItem[2] && (
            <ItemCarousel title={shownTagItem[2].title}>
              {
                shownTagItem[2].items.map(item => (
                  <ItemCard key={item.id} item={item} />
                ))
              }
              <ShowMoreCard onClick={() => navigate(`/items/tag/${shownTagItem[2].id}`)} />
            </ItemCarousel>
          )
        }

        <Grid container marginTop={1}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h4" fontWeight="bold" sx={{ fontSize: { xs: "1em", md: "2em" } }}>Ikuti Kami di Instagram</Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <iframe title="instagram" src="//lightwidget.com/widgets/3429372a60a258cf870b774366d79866.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style={{ width:"100%", border: "0",overflow:"hidden" }}></iframe> */}
            <iframe title="instagram" src="https://cdn.lightwidget.com/widgets/ea03dbfd5ae45bb7a7df510976601e91.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style={{ width:"100%", border: "0",overflow:"hidden" }}></iframe>
          </Grid>
        </Grid>
        
      </Container>
    
      <Footer />
    </>
  )
}