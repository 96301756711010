import axios from "axios"

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const CustomerService = {
  getItemTypeList() {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items/types`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListItem(tagMain = null, tagSecondary = null) {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items`, {
        params: {
          tagMainId: tagMain,
          tagSecondaryId: tagSecondary
        }
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getItemDetail(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListItemPageable(
    page, 
    perPage, 
    itemName = '', 
    tagMain = null, 
    tagSecondary = null, 
    brandId = null, 
    typeId = null, 
    sortBy = null,
    sortDirection = "asc"
  ) {
    return axios.get(`${cleanedBackendUrl}/items/pageable`, {
      params: {
        tagMainId: tagMain,
        tagSecondaryId: tagSecondary,
        brandId: brandId,
        itemName: itemName,
        typeId: typeId,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        perPage: perPage
      }
    });
  },
  fetchDataAddress(phoneNumber) {
    return axios.get(
      `${cleanedBackendUrl}/customer/${phoneNumber}/address`
    );
  },
  fetchDataOrderByPhone(phoneNumber) {
    return axios.get(
      `${cleanedBackendUrl}/payments/preview?phone-number=${phoneNumber}`
    );
  },
  getPaymentDataPreview(phoneNumber, paymentMethod, selectedPayment, selectedAddress) {
    return axios.get(
      `${cleanedBackendUrl}/payments/preview`,
      {
        params: {
          "phone-number": phoneNumber,
          method: paymentMethod === 'COD' ? null : paymentMethod,
          channel: selectedPayment === 'COD' ? null : selectedPayment,
          "address-id": selectedAddress,
        },
      }
    );
  },
  createPayment(phoneNumber, paymentMethod, paymentChannel, addressId, orderIdList) {
    return axios.post(
      `${cleanedBackendUrl}/payments`,
      {
        phoneNumber: phoneNumber,
        method: paymentMethod === 'COD' ? null : paymentMethod,
        channel: paymentChannel === 'COD' ? null : paymentChannel,
        addressId: addressId,
        orderIdList: orderIdList
      }
    );
  },
  getPaymentStatus(referenceId) {
    return axios.get(`${cleanedBackendUrl}/payments/${referenceId}`);
  },
  getDeliveryFee(itemId, zipCode) {
    return axios.get(
      `${cleanedBackendUrl}/payments/delivery-fee`,
      {
        params: {
          itemId: itemId,
          zipCode: zipCode
        }
      }
    );
  },
  deleteOrder(orderId) {
    return axios.put(
      `${cleanedBackendUrl}/orders/${orderId}/status`,
      {
        id: orderId,
        status: "CANCELED"
      }
    )
  },
  async getOrderHistory(phoneNumber) {
    try {
      const response = await axios.get(
        `${cleanedBackendUrl}/orders?phone-number=${phoneNumber}`
      );
      const orderData = response.data || {};
      
      const filtered = {}
      for (const key in orderData) {
        const baseData = orderData[key];
        if(baseData == null) continue;

        const notDoneOrder = []
        const completeOrder = []
        const canceledOrder = []

        for (const data of baseData) {
          if (data.status.key === 'WAITING_FOR_PAYMENT') {
            notDoneOrder.push(data)
          } else if (data.paymentReferenceId != null) {
            completeOrder.push(data)
          } else {
            canceledOrder.push(data)
          }
        }

        const notDoneOrderSorted = [];
        notDoneOrder.forEach((order) => {
          let existingGroup = notDoneOrderSorted.find(
            (group) => group.phoneNumber === order.phoneNumber
          );

          if (!existingGroup) {
            existingGroup = {
              username: order.username,
              phoneNumber: order.phoneNumber,
              paymentReferenceId: null,
              updatedDate: order.updatedDate,
              refunds: order.refunds,
              orderList: []
            };
            notDoneOrderSorted.push(existingGroup);
          }

          existingGroup.orderList.push(order);
        });

        const completeOrderSorted = [];
        completeOrder.forEach((order) => {
          let existingGroup = completeOrderSorted.find(
            (group) => group.phoneNumber === order.phoneNumber && group.paymentReferenceId === order.paymentReferenceId
          );

          if (!existingGroup) {
            existingGroup = {
              username: order.username,
              phoneNumber: order.phoneNumber,
              paymentReferenceId: order.paymentReferenceId,
              updatedDate: order.updatedDate,
              refunds: order.refunds,
              orderList: []
            };
            completeOrderSorted.push(existingGroup);
          }

          existingGroup.orderList.push(order);
        })

        const canceledOrderSorted = [];
        canceledOrder.forEach((order) => {
          canceledOrderSorted.push({
            username: order.username,
            phoneNumber: order.phoneNumber,
            paymentReferenceId: order.paymentReferenceId,
            updatedDate: order.updatedDate,
            refunds: order.refunds,
            orderList: [order]
          })
        })

        const sortedData = [...notDoneOrderSorted, ...completeOrderSorted, ...canceledOrderSorted]
        sortedData.sort((a, b) => {
          const [date1, hour1] = a.updatedDate?.split(" ");
          const [day1, month1, year1] = date1?.split("/").map(Number);
          const dateA = new Date(year1, month1 - 1, day1, ...hour1.split(":").map(Number));

          const [date2, hour2] = b.updatedDate?.split(" ");
          const [day2, month2, year2] = date2?.split("/").map(Number);
          const dateB = new Date(year2, month2 - 1, day2, ...hour2.split(":").map(Number));

          return dateB - dateA
        })

        filtered[key + 'Filtered'] = sortedData
      }

      return filtered
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default CustomerService