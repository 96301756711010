import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import {
  Backdrop,
  Fade,
  Grid,
  Modal,
  Button,
  Tooltip,
  styled,
  alpha,
  Drawer,
  Tabs,
  Tab,
} from "@mui/material";
import { useAuth } from "../authContext";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import logoToko from "../assets/logo_copy.png";
import { useNavigate } from "react-router-dom";
import TagService from "../services/manager/tagService";
import './navigation.css';

export default function Navigation() {
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [tagMainList, setTagMain] = useState([]);
  const [tagSecondaryList, setTagSecondary] = useState([]);
  const [tabValue, setTabValue] = useState(null);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    setMobileMoreAnchorEl(null);
  }
  const handleCloseLogout = () => setOpenLogout(false);
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [menuProfileAnchor, setMenuProfileAnchor] = useState(null);
  const openProfile = Boolean(menuProfileAnchor);
  const handleClickProfile = (event) => {
    setMenuProfileAnchor(event.currentTarget);
  }
  const handleCloseProfile = () => {
    setMenuProfileAnchor(null);
  }
  
  const { auth, logout } = useAuth();
  const getUsername = auth.user ? auth.user.username : "";

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(3),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    width: {
      xs: "20px",
      md: "40px"
    }
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      // paddingRight: `calc(1em + ${theme.spacing(1)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const tabStyle = {
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#FE8A01",
      variant: "h6",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#FE8A01",
      variant: "h6",
    },
    backgroundColor: "white",
    color: "black",
  };

  const handleNavigateHistory = () => {
    if (auth.user) {
      navigate('/customer/historyPage');
    } else {
      navigate('/login');
    }
    setMobileMoreAnchorEl(null);
  };

  const handleNavigateWishlist = () => {
    if (auth.user) {
      navigate('/customer/wishlist');
    } else {
      navigate('/login');
    }
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("orderid");
    setOpenLogout(false);
    logout();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDrawer = (reason) => {
    if (reason !== undefined) {
      setToggleDrawer(false);
    }
  }

  const handleCartButton = () => {
    console.log("masuk");
    
    if (auth.user) {
      navigate("/customer/cart")
    } else {
      navigate("/login")
    }
    setMobileMoreAnchorEl(null);
  }

  const getTagList = async () => {
    try {
      const response = await TagService.getTagList("");
      if (response) {
        const data = response.data.tagModelList;
        const tagMainData = data.filter((tag) => tag.flag === "main");
        setTagMain(tagMainData);
        if (tagMainData.length > 0) {
          setTabValue(tagMainData[0].id);
        }

        setTagSecondary(data.filter((tag) => tag.flag === "secondary"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const navigateTag = (tag) => {
    if (tabValue != null) {
      sessionStorage.setItem("mainTag", tabValue);
    }
    navigate(`/items/tag/${tag.id}`)
  }

  useEffect(() => {
    getTagList();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      // style={{ backgroundColor: "#FE8A01" }}
    >
      <MenuItem>
        <IconButton size="large" color="inherit" onClick={handleNavigateHistory}>
          <LocalMallIcon />
        </IconButton>
        <p>History</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" color="inherit" onClick={handleCartButton}>
          <ShoppingCartIcon />
        </IconButton>
        <p>Cart</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" color="inherit" onClick={handleNavigateWishlist}>
          <FavoriteIcon />
        </IconButton>
        <p>Wishlist</p>
      </MenuItem>
      {
        auth.user ? (
          <>
            <MenuItem >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>{auth.user?.username}</p>
            </MenuItem>
            <MenuItem onClick={handleOpenLogout}>
              <IconButton size="large" color="inherit" >
                <LogoutIcon />
              </IconButton>
              <p>Log Out</p>
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => navigate("/login")}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>Log In</p>
          </MenuItem>
        )
      }
    </Menu>
  );

  const styleModalLogout = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "center",
    overflowY: "auto",
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} position="sticky" top={0} zIndex={1001}>
        <AppBar position="static" style={{ backgroundColor: "#FE8A01" }}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid
                item
                xs={4}
                md={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <IconButton aria-label="menu" onClick={() => setToggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Type any products here..."
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
              <Grid
                item
                xs={4}
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  className="header-logo"
                  src={logoToko}
                  alt="Lique Store Logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate('/')}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="History" 
                    sx={{ display: { md: "flex" } }}>
                    <IconButton
                     sx={{ color: "black", width: { sm: "30px", md: "40px"}, height: { sm: "30px", md: "40px"}, padding: {xs: "unset", md: "4px" } }}
                      onClick={handleNavigateHistory}
                    >
                      <HistoryIcon></HistoryIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Cart"
                    sx={{ display: { md: "flex" } }}
                  >
                    <IconButton sx={{ color: "black", width: { sm: "30px", md: "40px"}, height: { sm: "30px", md: "40px"}, padding: {xs: "unset", md: "4px" } }} onClick={handleCartButton}>
                      <LocalMallIcon></LocalMallIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Wishlist"
                    sx={{ display: { md: "flex" } }}
                  >
                    <IconButton sx={{ color: "black", width: { sm: "30px", md: "40px"}, height: { sm: "30px", md: "40px"}, padding: {xs: "unset", md: "4px" } }} onClick={handleNavigateWishlist}>
                      <BookmarkIcon></BookmarkIcon>
                    </IconButton>
                  </Tooltip>

                  {
                    auth.user ? (
                      <>
                        <Button
                          id="btn-profile"
                          aria-controls={openProfile ? 'profile-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openProfile ? 'true' : undefined}
                          sx={{ 
                            display: { md: "flex", 
                            width: { sm: "30px", md: "fit-content"}, 
                            height: { sm: "30px", md: "fit-content"}, 
                            padding: {xs: "unset", md: "4px" }}, 
                            minWidth: "unset",
                            '& .MuiButton-startIcon': {
                              paddingRight: { xs: "unset", md: "4px"}
                            }
                          }}
                          style={{ color: "black" }}
                          onClick={handleClickProfile}
                          startIcon={<PersonIcon />}
                        >
                          <Typography sx={{ display: { md: "flex", sm: "none", xs: "none" } }}>{getUsername}</Typography>
                        </Button>

                        <Menu
                          id="profile-menu"
                          anchorEl={menuProfileAnchor}
                          open={openProfile}
                          onClose={handleCloseProfile}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => navigate("/customer/profile")}>Profile</MenuItem>
                          <MenuItem onClick={handleOpenLogout}>Logout</MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <>
                        <Button 
                          sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
                          style={{ backgroundColor: "black", color: "white" }}
                          onClick={() => navigate("/login")}>
                          Log In
                        </Button>
                        <Tooltip
                          title="Login"
                          sx={{ display: { xl: "none", lg: "none", md: "none", sm: "flex" } }}
                        >
                          <IconButton sx={{ color: "black", width: { sm: "30px", md: "40px"}, height: { sm: "30px", md: "40px"}, padding: {xs: "unset", md: "4px" }, display: { xl: "none", lg: "none", md: "none", sm: "flex" }  }} 
                            onClick={() => navigate("/login")}>
                            <PersonIcon></PersonIcon>
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }

                  
                  <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    open={openLogout}
                    onClose={handleCloseLogout}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openLogout}>
                      <Box sx={styleModalLogout}>
                        <AccountCircle style={{ fontSize: 100 }} />
                        <Typography
                          id="spring-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Apakah anda yakin ingin keluar?
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Button variant="outlined" onClick={handleLogout}>
                            Ya
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleCloseLogout}
                            sx={{
                              ml: 2,
                              backgroundColor: "#FE8A01",
                              color: "white",
                            }}
                          >
                            Tidak
                          </Button>
                        </Box>
                      </Box>
                      {/* Isi modal logout */}
                    </Fade>
                  </Modal>
                  {/* <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                    sx={{ display: { sm: "flex", md: "none" } }}
                  >
                    <MoreIcon />
                  </IconButton> */}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>

      <Drawer open={toggleDrawer} onClose={() => setToggleDrawer(false)}>
        <Box sx={{ width: "350px" }} role="presentation" onClick={(_event, reason) => handleCloseDrawer(reason)}>
          <Box display="flex" marginTop="5px" marginLeft="5px" justifyContent="end">
            <IconButton aria-label="close-drawer" onClick={() => setToggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Tabs
              value={tabValue}
              onChange={(_event, value) => setTabValue(value)}
              variant="scrollable" 
              scrollButtons="auto" 
              allowScrollButtonsMobile 
              sx={{
                "& .MuiTabs-scroller": {
                  overflowY: "hidden", 
                  overflowX: "auto", 
                },
                "& .MuiTabs-flexContainer": {
                  flexWrap: "nowrap", 
                  justifyContent: "center",
                },
                "& .MuiTab-root": {
                  minWidth: "60px", 
                  whiteSpace: "nowrap",
                },
              }}
            >
              {tagMainList.map((tag) => (
                <Tab
                  key={tag.id}
                  value={tag.id}
                  label={tag.title}
                  sx={tabStyle}
                />
              ))}
            </Tabs>

            <Grid container rowGap={1} paddingX={3} marginTop={3}>
              {
                tagSecondaryList.map(tag => (
                  <Grid item key={tag.id} xs={12} onClick={() => navigateTag(tag)}
                    sx={{ 
                      width: "100%", 
                      height: "100px", 
                      backgroundColor: "black", 
                      borderRadius: "12px", 
                      position: "relative", 
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "end",
                      cursor: "pointer"
                    }}
                  >
                    <div className="image-overlay"></div>

                    <Typography variant="h5" color="white" margin="0 0 20px 20px">{tag.title}</Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}