import { Typography } from "@mui/material"

const ShowMoreCard = ({onClick}) => {
  return (
    <div style={{ padding: "10px", height: "95%", cursor: "pointer" }} onClick={onClick}>
      <div style={{ 
        height: "100%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        borderRadius: "12px",
        background: "black",
        textAlign: "center"
      }}>
        <Typography variant="h5" color="white">
          Show More
        </Typography>
      </div>
    </div>
  );
}

export default ShowMoreCard;