import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import noImage from '../../../assets/no-image.png'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../authContext';
import WishlistService from '../../../services/customer/wishlistService';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const ItemCard = ({item}) => {
  const imageUrl = "https://api.liquestore.com/assets/items/"
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [isBookmarked, setBookmarked] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false);

  const btnWishlistStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    borderRadius: "100px",
    width: { xs: "30px" , md: "45px"},
    height: { xs: "30px" , md: "45px"},
    minWidth: "unset",
    backgroundColor: "white",
    color: "#FE8A01",
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, .1)"
  };

  const getWishlistStatus = async () => {
    try {
      const response = await WishlistService.getWishlistStatus(auth?.user.id, item?.id);
      if (response) {
        const status = response.data.status;
        setBookmarked(status)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const addWishlist = async () => {
    try {
      const response = await WishlistService.addWishlist(auth?.user.id, item?.id);
      if (response) {
        setBookmarked(true)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const deleteWishlist = async () => {
    try {
      const response = await WishlistService.deleteWishlist(auth?.user.id, item?.id);
      if (response) {
        setBookmarked(false)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const handleBookmark = async () => {
    if (auth?.user) {
      setLoadingWishlist(true);
      if (isBookmarked) {
        await deleteWishlist();
      } else {
        await addWishlist();
      }
      setLoadingWishlist(false);
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    getWishlistStatus()
  }, [item])

  return (
    <div style={{ position: "relative" }}>
      <Button
        sx={btnWishlistStyle}
        onClick={handleBookmark}
        disabled={loadingWishlist}
      >
        {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
      </Button>

      <div style={{ padding: "10px", cursor: 'pointer' }} onClick={() => navigate(`/item/${item.id}`)}>
        <Grid container>
          <Grid item xs={12} borderRadius="12px" overflow="hidden">
            <img
              src={item.images == null ? noImage : `${imageUrl}${item.images[0]}`}
              alt={item.name}
              style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "cover" }}
            ></img>
          </Grid>

          <Grid item xs={12} marginTop={1} textAlign="center">
            {
              item?.brand && (
                <>
                  <img 
                    src={item?.brand.images == null ? noImage : `${imageUrl}${item.brand.images[0]}`}
                    alt={item?.brand.title}
                    style={{ width: "60%", aspectRatio: "6 / 2", objectFit: "contain" }}
                  />
                </>
              )
            }
          </Grid>

          <Grid item xs={12} marginTop={1} textAlign="center">
            <Typography variant="h5" sx={{ fontSize: { xs: ".8em", md: "1.5em" } }}>
              <b>{item.name}</b>
            </Typography>
            <Typography variant="h6" sx={{ fontSize: { xs: ".8em", md: "1.5em" } }}>
              <NumericFormat
                value={item.defaultPrice}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp. "}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ItemCard;