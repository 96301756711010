import { useSpring, animated } from "@react-spring/web";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import { useAuth } from "../authContext";
import TagService from "../services/manager/tagService";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CssBaseline,
  Drawer,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AccountCircle } from "@mui/icons-material";
import AdminSidebar from "./sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { useDropzone } from "react-dropzone";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ImageSortableList from "./components/imageSortableList";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const btnAddStyle = {
  justifyContent: "center",
  width: "15vw",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
  marginTop: "20px",
};

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  textAlign: "center",
};

const styleModalTambah = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function ReviewTag() {
  const { auth, logout } = useAuth();

  const [rows, setRows] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setmsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [listTag, setListTag] = useState([]);
  const [openTambah, setOpenTambah] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [errors, setErrors] = useState({});
  const [showMsgImage, setShowMsgImage] = useState(false);
  const [msgImageError, setMsgImageError] = useState("");

  const [msgError, setMsgError] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState("");
  const [shown, setShown] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  const getusername = auth.user ? auth.user.username : "";
  const drawerWidth = 300;

  const columns = [
    {
      field: "title",
      headerName: "Nama Tag",
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Deskripsi",
      flex: 1,
      editable: false,
    },
    {
      field: "flag",
      headerName: "Tipe Tag",
      flex: 1,
      editable: false,
    },
    {
      field: "shown",
      headerName: "Ditampilkan di Halaman Utama?",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.shown === 1 ? "Ya" : "Tidak"}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpenEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDelete(params.row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Handle the files
      const validFiles = acceptedFiles.filter((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );
      console.log(acceptedFiles);
      if (validFiles.length > 0) {
        setShowMsgImage(false);
        const filePreviews = validFiles.map((file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          return new Promise((resolve) => {
            reader.onload = () => {
              resolve({
                path: file.name,
                preview: reader.result,
                originalFile: file,
              });
            };
          });
        });

        Promise.all(filePreviews)
          .then((images) => {
            setImages(images);
          })
          .catch((error) => {
            console.error("Error reading files:", error);
          });
      } else {
        setShowMsgImage(true);
        setMsgImageError(
          "Invalid file types detected. Please upload only image files."
        );
        console.error(
          "Invalid file types detected. Please upload only image files."
        );
      }
    },
    [setImages]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const validate = () => {
    let tempErrors = {};

    if (!title) {
      tempErrors.title = "Nama tag harus diisi";
    }

    if (!flag) {
      tempErrors.flag = "Flag harus dipilih";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const handleOpenEdit = (row) => {
    setTitle(row.title);
    setUpdateId(row.id);
    setDescription(row.description);
    setFlag(row.flag);
    setShown(row.shown === 1 ? true : false);
    setOpenEdit(true);
    setImages([]);
    setOldImages(row.images || []);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateId("");
    setTitle("");
    setDescription("");
    setFlag("");
    setShown(false);
    setImages([]);
    setOldImages([]);
  };

  const handleOpenTambah = () => setOpenTambah(true);
  const handleCloseTambah = () => {
    setOpenTambah(false);
    setErrors({});
    setTitle("");
    setDescription("");
    setFlag("");
    setShown(false);
    setImages([]);
    setOldImages([]);
  };

  const handleOpenDelete = (row) => {
    setUpdateId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setUpdateId("");
  };

  const handleDeleteImage = (name) => {
    setOldImages(oldImages.filter(image => image !== name));
  }

  const getAllTag = async (flag = "") => {
    try {
      const response = await TagService.getTagList(flag);
      if (response) {
        const data = response.data.tagModelList;
        setListTag(data);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await TagService.insertTag(
          title,
          description,
          flag,
          shown,
          images
        );
        if (response) {
          setmsgSuccess("Add tag success");
          activateSuccess();
          getAllTag();
        }
      } catch (error) {
        console.error(error);
        setMsgError("Error while inserting tags");
        activateError();
      } finally {
        handleCloseTambah();
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await TagService.updateTag(
          updateId,
          title,
          description,
          shown,
          images,
          oldImages
        );
        if (response) {
          setmsgSuccess("Update tag success");
          activateSuccess();
          getAllTag();
        }
      } catch (error) {
        console.error(error);
        setMsgError("Error while updating tags");
        activateError();
      } finally {
        handleCloseEdit();
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await TagService.deleteTag(updateId);
      if (response) {
        setmsgSuccess("Delete tag success");
        activateSuccess();
        getAllTag();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        setMsgError("Gagal Hapus Tag, Tag masih dipakai oleh beberapa stok");
      } else {
        setMsgError("Error while deleting tags");
      }
      activateError();
    } finally {
      handleCloseDelete();
    }
  };

  useEffect(() => {
    getAllTag();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <AdminSidebar />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getusername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ mr: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

        <Toolbar />

        <RootContainer>
          {showSuccess && (
            <Alert
              variant="filled"
              severity="success"
              style={{ marginBottom: 3 }}
            >
              {msgSuccess}
            </Alert>
          )}
          {showError && (
            <Alert
              variant="filled"
              severity="error"
              style={{ marginBottom: 3 }}
            >
              {msgError}
            </Alert>
          )}

          <Typography variant="h3" marginBottom={5}>
            Kelola Tag
          </Typography>

          <Box sx={{ width: "100%" }}>
            <DataGrid
              rows={listTag}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
          <Button style={btnAddStyle} onClick={handleOpenTambah}>
            + Tambah Tag
          </Button>
        </RootContainer>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openTambah}
          onClose={handleCloseTambah}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openTambah}>
            <Box sx={styleModalTambah}>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>Nama Tag *</Typography>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      value={title}
                      error={!!errors.title}
                      helperText={errors.title}
                      FormHelperTextProps={{ sx: { color: "red" } }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Deskripsi</Typography>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      autoComplete="off"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {!!errors.description ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.description}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Ditampilkan di Halaman Utama ?</Typography>
                    <Select
                      fullWidth
                      autoComplete="off"
                      value={shown}
                      onChange={(e) => setShown(e.target.value)}
                    >
                      <MenuItem value={true}>Ya</MenuItem>
                      <MenuItem value={false}>Tidak</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Tipe Tag *</Typography>
                    <RadioGroup
                      row
                      name="flag"
                      value={flag}
                      onChange={(e) => setFlag(e.target.value)}
                    >
                      <FormControlLabel
                        value="main"
                        control={<Radio />}
                        label="Main"
                      />
                      <FormControlLabel
                        value="secondary"
                        control={<Radio />}
                        label="Secondary"
                      />
                      <FormControlLabel
                        value="brand"
                        control={<Radio />}
                        label="Brand"
                      />
                    </RadioGroup>
                    {!!errors.flag ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.flag}
                      </FormHelperText>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: "2px dashed #aaa",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
                        transition: "background-color 0.2s",
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Typography>Drop the files here...</Typography>
                      ) : (
                        <Typography>
                          Drag 'n' drop some files here, or click to select
                          files
                        </Typography>
                      )}
                    </Box>
                    {!showMsgImage && (
                      <Box
                        mt={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {images.map((file, index) => (
                          <Box key={index} mb={2} mr={2}>
                            <Typography>{file.path}</Typography>
                            <img
                              src={file.preview}
                              alt={file.path}
                              style={{ maxWidth: "70%", maxHeight: "100px" }}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                    {showMsgImage && (
                      <Typography sx={{ color: "red" }}>
                        {msgImageError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openEdit}
          onClose={handleCloseEdit}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openEdit}>
            <Box sx={styleModalTambah}>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>Nama Tag *</Typography>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      value={title}
                      error={!!errors.title}
                      helperText={errors.title}
                      FormHelperTextProps={{ sx: { color: "red" } }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Deskripsi</Typography>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      autoComplete="off"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {!!errors.description ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.description}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Ditampilkan di Halaman Utama ?</Typography>
                    <Select
                      fullWidth
                      autoComplete="off"
                      value={shown}
                      onChange={(e) => setShown(e.target.value)}
                    >
                      <MenuItem value={true}>Ya</MenuItem>
                      <MenuItem value={false}>Tidak</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Tipe Tag *</Typography>
                    <RadioGroup
                      row
                      name="flag"
                      value={flag}
                      onChange={(e) => setFlag(e.target.value)}
                    >
                      <FormControlLabel
                        value="main"
                        control={<Radio />}
                        label="Main"
                        disabled
                      />
                      <FormControlLabel
                        value="secondary"
                        control={<Radio />}
                        label="Secondary"
                        disabled
                      />
                      <FormControlLabel
                        value="brand"
                        control={<Radio />}
                        label="Brand"
                        disabled
                      />
                    </RadioGroup>
                    {!!errors.flag ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.flag}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: "2px dashed #aaa",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
                        transition: "background-color 0.2s",
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Typography>Drop the files here...</Typography>
                      ) : (
                        <Typography>
                          Drag 'n' drop some files here, or click to select
                          files
                        </Typography>
                      )}
                    </Box>
                    {!showMsgImage && (
                      <Box
                        mt={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {images.map((file, index) => (
                          <Box key={index} mb={2} mr={2}>
                            <Typography>{file.path}</Typography>
                            <img
                              src={file.preview}
                              alt={file.path}
                              style={{ maxWidth: "70%", maxHeight: "100px" }}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                    {showMsgImage && (
                      <Typography sx={{ color: "red" }}>
                        {msgImageError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Gambar Sekarang</Typography>
                      <DndProvider backend={HTML5Backend}>
                        <ImageSortableList 
                          images={oldImages}
                          setImages={setOldImages}
                          alt={title}
                          deleteItem={handleDeleteImage}
                        />
                      </DndProvider>
                    <Grid container spacing={2} marginTop={0}>
                      {
                        oldImages.length <= 0 ? (
                          <Grid item xs={12} textAlign="center">Belum ada Gambar</Grid>
                        ) : null
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      fullWidth
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openDelete}
          onClose={handleCloseDelete}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openDelete}>
            <Box sx={styleModal}>
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah kamu yakin ingin membuang data ini?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleCloseDelete}>
                  Batal
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleConfirmDelete}
                  sx={{ ml: 2, backgroundColor: "red", color: "white" }}
                >
                  Buang
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
}
