import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const CartService = {
  getCartList(customerId, updateStock = 1) {
    return axios.get(`${cleanedBackendUrl}/cart?customerId=${customerId}&updateStock=${updateStock}`);
  },
  changeCartStatus(cartId, checked, quantity) {
    return axios.post(`${cleanedBackendUrl}/cart/update`, {
      cartId: cartId,
      checked: checked,
      quantity: quantity
    });
  },
  deleteCart(cartId) {
    return axios.delete(`${cleanedBackendUrl}/cart`, {
      data: {
        cartId: cartId,
      },
    });
  },
  addToCart(customerId, itemId, quantity, variantId) {
    return axios.post(`${cleanedBackendUrl}/cart`, {
      customerId: customerId,
      itemId: itemId,
      quantity: quantity,
      variantId: variantId
    });
  },
	proceedToPayment(cartIds) {
		return axios.post(`${cleanedBackendUrl}/cart/proceed`, {
			cartIds: cartIds
		})
	} 
};

export default CartService;
