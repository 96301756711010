import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "./navigation";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Container, Grid, IconButton, LinearProgress, linearProgressClasses, MenuItem, Select, styled, TextField, Typography } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import ItemCard from "./components/item/itemCard";
import TagService from "../services/manager/tagService";
import Footer from "./components/footer/footer";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#6A6A6A",
  },
}));

const btnStyle = {
  borderRadius: "12px",
  width: "100%",
  backgroundColor: "black",
  color: "white",
  padding: 5,
  fontSize: "1em",
  opacity: 1
};

const btnDisabledStyle = {
  borderRadius: "12px",
  width: "100%",
  backgroundColor: "black",
  color: "white",
  padding: 5,
  fontSize: "1em",
  opacity: 0.5
};

const MoreItemsPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [itemTypeList, setItemTypeList] = useState([]);
  const [tagSecondaryList, setTagSecondaryList] = useState([]);
  const [tagMainList, setTagMainList ] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [tempItemList, setTempItemList] = useState([]);
  const [title, setTitle] = useState([]);
  const [selectedMainTag, setSelectedMainTag] = useState(null);
  const [selectedSecondaryTag, setSelectedSecondaryTag] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [itemName, setItemName] = useState("");
  const [submittedItemName, setSubmittedItemName] = useState("");
  const [chosenTag, setChosenTag] = useState(null);
  const [sortBy, setSortBy] = useState("1");
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const perPage = 10;
  const navigate = useNavigate();
  const { tag } = useParams();

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const onTypeSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    if (selectedType === id) {
      setSelectedType(null);
    } else {
      setSelectedType(id);
    }
  }

  const onMainTagSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    if (selectedMainTag === id) {
      setSelectedMainTag(null);
    } else {
      setSelectedMainTag(id);
    }
  }
  
  const onSecondaryTagSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    if (selectedSecondaryTag === id) {
      setSelectedSecondaryTag(null);
    } else {
      setSelectedSecondaryTag(id);
    }
  }
  
  const onBrandSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    if (selectedBrand === id) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(id);
    }
  }

  const onSubmitItemName = () => {
    setPage(1);
    setTempItemList([]);
    setSubmittedItemName(itemName)
  }

  const onChangeSortBy = (data) => {
    setPage(1);
    setTempItemList([]);
    setSortBy(data);
  }

  const handleKeyDownItemName = (event) => {
    if (event.key === 'Enter') {
      onSubmitItemName();
    }
  };

  const getItemTypeList = async () => {
    try {
      const response = await CustomerService.getItemTypeList();
      if (response) {
        const data = response.data;
        setItemTypeList(data.itemTypeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const getAllTag = async () => {
    try {
      const response = await TagService.getTagList("");
      if (response) {
        const data = response.data.tagModelList;
        setTagSecondaryList(data.filter(d => d.flag === 'secondary'));
        setTagMainList(data.filter(d => d.flag === 'main'));
        setBrandList(data.filter(d => d.flag === 'brand'));

        let title = ""
        if (tag) {
          const tagFound = data.find(t => t.id === parseInt(tag));
          setChosenTag(tagFound);
          title = tagFound?.title;
        }
        setTitle(title)
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  async function fetchItems() {
    try {
      let by = null;
      let direction = "asc";

      if (sortBy === "1") {
        by = "date";
        direction = "desc";
      } else if (sortBy === "2") {
        by = "price";
        direction = "asc";
      } else if (sortBy === "3") {
        by = "price";
        direction = "desc";
      }

      const responseItemList = await CustomerService.getListItemPageable(
        page,
        perPage,
        submittedItemName,
        chosenTag?.flag === 'main' ? chosenTag?.id : selectedMainTag,
        chosenTag?.flag === 'secondary' ? chosenTag?.id : selectedSecondaryTag,
        chosenTag?.flag === 'brand' ? chosenTag?.id : selectedBrand,
        selectedType,
        by,
        direction
      );
      
      if (responseItemList) {
        const data = responseItemList.data;
        setItemList([...tempItemList, ...data.itemList]);
        setTempItemList([...tempItemList, ...data.itemList]);
        setTotalItem(data.totalData);
        setTotalPage(data.totalPage);

        if (data.itemList.length > 0) {
          setPage(page + 1);
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setFirstLoad(true);
    getItemTypeList();
    getAllTag();

    const sessionData = sessionStorage.getItem("mainTag");
    if (sessionData != null) {
      setSelectedMainTag(parseInt(sessionData));
      sessionStorage.removeItem("mainTag");
    }
  }, [tag]);

  useEffect(() => {
    if (firstLoad) {
      setPage(1);
      setTempItemList([]);
      setFirstLoad(false);
    }
  }, [firstLoad])
  
  useEffect(() => {
    if (chosenTag) {
      fetchItems();
    }
  }, [chosenTag, selectedMainTag, selectedSecondaryTag, selectedBrand, selectedType, submittedItemName, sortBy])

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ minHeight: "100vh" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} columnSpacing={5}>
          
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  placeholder="Cari Barang"
                  onKeyDown={handleKeyDownItemName}
                  sx={{ border: "1px solid black", borderRadius: "12px" }}
                />
              </Grid>
              <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
                <IconButton onClick={onSubmitItemName}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} marginTop={3}>
            <Typography variant="h3" sx={{ fontSize: { xs: "1em", md: "2em" } }} textAlign="center" fontWeight="bold" color={"#000"} textTransform="uppercase">
              {title}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: { xs: ".8em", md: "1em" } }} textAlign="center" color={"#000"}>
              {chosenTag?.description}
            </Typography>
          </Grid>
          
          <Grid item xs={12} marginY={1}>
            <hr style={{ height: '1px', background: 'black', border: "none" }}></hr>
          </Grid>
        </Grid>

        <Grid container marginBottom={5} columnSpacing={5}>
          <Grid item xs={12} md={4} lg={3} marginBottom={2} sx={{ order: {xs: 3, md: "unset"} }}>
            <Accordion defaultExpanded sx={{ background: "transparent", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel-gender-content"
                id="panel-gender-header"
                sx={{ borderBottom: "1px solid black", paddingBottom: "5px" }}
              >
                <Typography variant="h5" sx={{ fontSize: { xs: "1em", md: "1.3em" }, fontWeight: "bold" }}>Gender</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  tagMainList.map(tag =>
                    <Typography key={tag.id} onClick={() => onMainTagSelected(tag.id)} 
                      sx={{ 
                        fontWeight: (tag.id === selectedMainTag ? 'bold' : 'normal'), 
                        cursor: "pointer",
                        marginTop: "5px",
                        fontSize: { xs: ".8em", md: "1em" }
                      }}
                    >
                      {tag.title}
                    </Typography>
                  )
                }
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel-type-content"
                id="panel-type-header"
                sx={{ borderBottom: "1px solid black", paddingBottom: "5px" }}
              >
                <Typography variant="h5" sx={{ fontSize: { xs: "1em", md: "1.3em" }, fontWeight: "bold" }}>Product Type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  itemTypeList.map(itemType =>
                    <Typography key={itemType.id} onClick={() => onTypeSelected(itemType.id)} 
                      sx={{ 
                        fontWeight: (tag.id === selectedMainTag ? 'bold' : 'normal'), 
                        cursor: "pointer",
                        marginTop: "5px",
                        fontSize: { xs: ".8em", md: "1em" }
                      }}
                    >
                      {itemType.name}-{itemType.variant}
                    </Typography>
                  )
                }
              </AccordionDetails>
            </Accordion>


            {
              chosenTag?.flag !== 'secondary' && (
                <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel-tag-content"
                    id="panel-tag-header"
                    sx={{ borderBottom: "1px solid black", paddingBottom: "5px" }}
                  >
                    <Typography variant="h5" sx={{ fontSize: { xs: "1em", md: "1.3em" }, fontWeight: "bold" }}>Tag</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      tagSecondaryList.map(tag =>
                        <Typography key={tag.id} onClick={() => onSecondaryTagSelected(tag.id)} 
                          sx={{ 
                            fontWeight: (tag.id === selectedMainTag ? 'bold' : 'normal'), 
                            cursor: "pointer",
                            marginTop: "5px",
                            fontSize: { xs: ".8em", md: "1em" }
                          }}
                        >
                          {tag.title}
                        </Typography>
                      )
                    }
                  </AccordionDetails>
                </Accordion>
              )
            }

            {
              chosenTag?.flag !== 'brand' && (
                <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel-brand-content"
                    id="panel-brand-header"
                    sx={{ borderBottom: "1px solid black", paddingBottom: "5px" }}
                  >
                    <Typography variant="h5" sx={{ fontSize: { xs: "1em", md: "1.3em" }, fontWeight: "bold" }}>Brand</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      brandList.map(brand =>
                        <Typography key={brand.id} onClick={() => onBrandSelected(brand.id)} 
                          sx={{ 
                            fontWeight: (tag.id === selectedMainTag ? 'bold' : 'normal'), 
                            cursor: "pointer",
                            marginTop: "5px",
                            fontSize: { xs: ".8em", md: "1em" }
                          }}
                        >
                          {brand.title}
                        </Typography>
                      )
                    }
                  </AccordionDetails>
                </Accordion>
              )
            }
          </Grid>

          <Grid item xs={12} md={8} lg={9} marginBottom={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={12} md={4} display="flex" alignItems="center" marginBottom={1}>
                    <Typography variant="h5" sx={{ fontSize: { xs: ".8em", md: "1.2em" } }} ><b>{totalItem}</b> items found</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} fontSize="1.2em">
                    <Select
                      fullWidth
                      labelId="label-size"
                      id="select-size"
                      value={sortBy}
                      label="Age"
                      onChange={(event) => onChangeSortBy(event.target.value)}
                      sx={{ border: "1px solid black", borderRadius: "12px", height: "30px", fontSize: ".8em" }}
                    >
                      <MenuItem value="1">Urutkan Berdasarkan Tanggal: Baru ke Lama</MenuItem>
                      <MenuItem value="2">Urutkan Berdasarkan Harga: Rendah ke Tinggi</MenuItem>
                      <MenuItem value="3">Urutkan Berdasarkan Harga: Tinggi ke Rendah</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              {itemList.map((item) => (
                <Grid item xs={4} md={4} key={item.id}>
                  <ItemCard key={item.id} item={item} />
                </Grid>
              ))}
              {itemList.length <= 0 && (
                <Grid container >
                  <Grid item xs={12} textAlign="center" marginTop={3}>
                    <Typography variant="h6" textAlign="center" color={"black"}>
                      No item found
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container justifyContent="center" marginTop={3}>
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: ".8em", md: "1.2em" } }} >
                  Showing {itemList.length} of {totalItem}
                </Typography>
                <Box marginTop={2}>
                  <BorderLinearProgress variant="determinate" value={itemList.length / (totalItem || 1) * 100} />
                </Box>
                <Box textAlign="center" marginTop={2}>
                  <Button style={itemList.length === parseInt(totalItem) ? btnDisabledStyle : btnStyle} 
                    onClick={fetchItems} disabled={itemList.length === parseInt(totalItem)}>Show More</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default MoreItemsPage;
