import React, { useState } from "react";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import logoDana from "../../../assets/logoDana.png";
import logoShopeePay from "../../../assets/logoShopeePay.svg";
import logoOvo from "../../../assets/logoOvo.png";
import logoLinkAja from "../../../assets/logoLinkAja.png";
import logoJeniusPay from "../../../assets/logoJeniusPay.png";
import logoAstraPay from "../../../assets/logoAstraPay.png";
import logoBca from "../../../assets/bca.png";
import logoMandiri from "../../../assets/mandiri.png";
import logoBri from "../../../assets/bri.svg";
import logoBni from "../../../assets/bni.svg";
import logoCimb from "../../../assets/cimb.svg";
import logoPermata from "../../../assets/permata.svg";
import logoSampoerna from "../../../assets/sampoerna.svg";
import logoBjb from "../../../assets/bjb.svg";
import logoBsi from "../../../assets/bsi.svg";
import logoQris from "../../../assets/qris.svg";
import logoToko from "../../../assets/logo_copy.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box padding={1} sx={{ background: "#FE8A01", color: "black", borderTopRightRadius: "30px", borderTopLeftRadius: "30px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} sx={{ textAlign: { sm: "left", md: "center" } }}>
            <img
              className="image-logo"
              src={logoToko}
              alt="Lique Store Logo"
              style={{ cursor: "pointer", maxWidth: "100%" }}
              onClick={() => navigate('/')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={5} md={6} textAlign="center" display="flex" flexDirection="column" gap="10px">
                <Typography variant="h6" fontWeight="bold" color="white" sx={{ fontSize: { xs: ".8em", md: "1.2em" } }}>TENTANG KAMI</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>
                  <span onClick={() => navigate("/about")}>Tentang Lique Store</span>
                </Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Temukan lokasi kami</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>
                  <span onClick={() => navigate("/about#karir")}>Karir</span>
                </Typography>
              </Grid>
              <Grid item xs={7} md={6} textAlign="center" display="flex" flexDirection="column" gap="10px">
                <Typography variant="h6" fontWeight="bold" color="white" sx={{ fontSize: { xs: ".8em", md: "1.2em" } }}>BANTUAN & DUKUNGAN</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Bantuan</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Status Order</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Pengiriman & Pengantaran</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>
                  <span onClick={() => navigate("/about#kebijakan-pengembalian")}>Kebijakan Pengembalian</span>
                </Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Pilihan Pembayaran</Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>
                  <span onClick={() => navigate("/about#contact-us")}>Kontak Kami</span>
                </Typography>
                <Typography variant="h6" sx={{ cursor: "pointer", fontSize: { xs: ".8em", md: "1.2em" } }}>Ulasan Pelanggan</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container sx={{ height: "100%" }} spacing={3}>
              <Grid item xs={7} md={12} height="fit-content" sx={{ alignSelf: { xs: "end", md: "start"} }}>
                <Grid container spacing={1} sx={{ justifyContent: { xs: "start", md: "end"} }}>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoDana} alt="logo dana" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoOvo} alt="logo Ovo" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoMandiri} alt="logo Mandiri" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoBri} alt="logo Bri" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoBni} alt="logo Ovo" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoCimb} alt="logo Cimb" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoPermata} alt="logo Permata" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoSampoerna} alt="logo SamplogoSampoerna" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoBjb} alt="logo Bjb" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoBsi} alt="logo Bsi" width="100%"></img>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} textAlign="center">
                    <img src={logoQris} alt="logo Qris" width="100%"></img>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} md={12} height="fit-content" alignSelf="flex-end">
                <Grid container justifyContent="end">
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <SvgIcon color="white" sx={{ fontSize: {xs: 21, md: 31} }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        {" "}
                        <path fill="black" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                      </svg>
                    </SvgIcon>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <InstagramIcon sx={{ fontSize: {xs: 24, md: 34} }} />
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <WhatsAppIcon sx={{ fontSize: {xs: 24, md: 34} }} />
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <YouTubeIcon sx={{ fontSize: {xs: 30, md: 40} }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
