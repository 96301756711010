import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import liqueAboutBg from "../assets/liqueAboutBg.png"
import liqueContactUs from "../assets/liqueContactUs.png"
import Navigation from "./navigation";
import Footer from "./components/footer/footer";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import InboxIcon from '@mui/icons-material/Inbox';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from '@mui/icons-material/Email';

const AboutPage = () => {
  return (
    <>
      <Navigation />
    
      <Container maxWidth="unset" sx={{ padding: { sm: "unset", md: "unset" } }} >
        <Box width="100%" position="relative" height="fit-content">
          <Box width="100%" sx={{ display: {xs: "none", md: "block"} }}>
            <img src={liqueAboutBg} alt="Lique Background" width="100%"  />
          </Box>

          <Grid container top="0px" alignItems="center" justifyContent="end" height="100%" spacing={5} 
            sx={{ paddingRight: { xs: "unset", md: "60px", lg: "100px" }, position: {sm: "relative", md: "absolute"} }}
          >
            <Grid item xs={12} md={8} lg={7} xl={6} textAlign="center" 
              sx={{ marginTop: {xs: "50px", md: "unset"}, color: {xs: "black", md: "white"}, paddingLeft: {xs: "20px", md: "unset"} }}
            >
              <Typography variant="h4" fontWeight="bold">TENTANG LIQUE STORE</Typography>
              <Typography variant="h6" marginTop={3} sx={{ fontSize: { xl: "1.5em", lg: "1.1em", md: "1em"} }}>
                Every piece of clothing has a story, and at Lique Store, we want to help that piece find a new home. We believe every garment has the potential to be worn and loved again. By giving them a second chance, we're reducing global fashion waste and making a positive impact on both the community and the environment. At Lique Store, we're not just about selling second hand clothes. We offer a blend of  thrift items and our very own brand, Liquify. Through Liquify, we collaborate with local production facilities to create stylish, high-quality streetwear that contributes to the growth of Indonesia's fashion industry. <br /> 
                In addition to offering thrifted items, we are passionate about sustainability. We embrace upcycling and reworking, constantly refining our processes to give clothes a fresh spin. By enhancing each piece where needed, we breathe new life into them, making them full of purpose. Thank you for being part of this journey with us. <br /><br/>
                Lique store - More Than a Thrift Store
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container component="main" maxWidth="xl" sx={{ marginY: "50px" }}>
        <Box id="kebijakan-pengembalian">
          <Typography variant="h4" fontWeight="bold" textAlign="center">
            Kebijakan Pengembalian Barang (Retrun Policy)
          </Typography>
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            Kami Lique Store berkomitmen untuk memastikan kepuasan Anda dalam berbelanja. <br /> 
            Baca kebijakan pengembalian untuk pembelian baju thrifting:
          </Typography>

          <Grid container columnSpacing={3} marginTop={5}>
            <Grid item xs={12} md={4}>
              <Box sx={{ background: "#FE8A01", color: "white", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center"}}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <RotateLeftIcon sx={{ color: "#FE8A01" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Ketentuan Pengembalian
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    Pengembalian hanya dapat dilakukan jika produk yang Anda terima:
                    <ul>
                      <li style={{ marginBottom: "10px" }}>Tidak sesuai dengan deskripsi produk yang tertera di website atau media sosial kami.</li>
                      <li style={{ marginBottom: "10px" }}>Memiliki cacat yang signifikan yang tidak diinformasikan sebelumnya.</li>
                      <li style={{ marginBottom: "10px" }}>Pengajuan pengembalian harus dilakukan dalam waktu 1 hari setelah barang diterima.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ background: "white", color: "#FE8A01", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center"}}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "#FE8A01", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CurrencyExchangeIcon sx={{ color: "white" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Pengembalian Dana
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    <ul>
                      <li style={{ marginBottom: "10px" }}>Dana akan dikembalikan setelah barang yang dikembalikan kami terima dan proses dalam waktu 1-3 hari kerja.</li>
                      <li style={{ marginBottom: "10px" }}>Jumlah dana yang dikembalikan akan dipotong biaya pengiriman sesuai ketentuan yang berlaku.</li>
                      <li style={{ marginBottom: "10px" }}>Dana akan ditransfer ke rekening yang Anda informasikan pada saat proses pengajuan pengembalian.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ background: "#FE8A01", color: "white", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ContentPasteSearchIcon sx={{ color: "#FE8A01" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Syarat Barang yang Dikembalikan
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    Pengembalian hanya dapat dilakukan jika produk yang Anda terima:
                    <ul>
                      <li style={{ marginBottom: "10px" }}>Barang harus dalam kondisi seperti saat diterima, termasuk tag atau label (jika ada) yang masih utuh.</li>
                      <li style={{ marginBottom: "10px" }}>Barang yang telah dicuci, digunakan, atau mengalami kerusakan akibat pemakaian tidak dapat dikembalikan.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ background: "white", color: "#FE8A01", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center"}}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "#FE8A01", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FolderOffIcon sx={{ color: "white" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Barang Non-Pengembalian
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    <ul>
                      <li style={{ marginBottom: "10px" }}>Barang yang telah diinformasikan memiliki cacat  (defect) pada deskripsi produk.</li>
                      <li style={{ marginBottom: "10px" }}>Barang dalam kategori sale atau clearance.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ background: "#FE8A01", color: "white", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <InboxIcon sx={{ color: "#FE8A01" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Prosedur Pengembalian
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    Hubungi kami melalui email liquecs@gmail untuk memberikan detail pengembalian:
                    <ul>
                      <li>Nomor pesanan.</li>
                      <li>Video unboxing.</li>
                      <li>Foto barang yang ingin dikembalikan.</li>
                      <li>Alasan pengembalian.</li>
                      <li>Kami akan memberikan alamat atau resi pengiriman dalam Waktu 1-3 hari kerja setelah pengajuan disetujui.</li>
                      <li>Biaya pengiriman untuk pengembalian ditanggung oleh pembeli.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ background: "white", color: "#FE8A01", borderTopRightRadius: "19px", marginTop: "-20px", padding: "20px", minHeight: "600px" }}>
                <Box sx={{ display: "flex", alignItems: "center"}}>
                  <div style={{ width: "50px", height: "50px", borderRadius: "100px", background: "#FE8A01", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <TextSnippetIcon sx={{ color: "white" }} />
                  </div>
                  <Typography variant="h5" fontWeight="bold" marginLeft="20px">
                    Catatan Penting
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography variant="h6" fontWeight="500">
                    Kami menjual barang thrift yang mungkin memiliki tanda pemakaian. Hal ini menjadi bagian dari karakter unik produk dan bukan dianggap sebagai cacat. 
                    Kami menjamin kualitas barang yang dijual dan bersikap transparan mengenai kondisi semua produk kami pada saat pembelian. 
                    Jika Anda memiliki pertanyaan terkait barang yang termasuk dalam kategori sale, auction, atau diskon, 
                    silakan hubungi customer service kami untuk mendapatkan informasi lebih lanjut.
                    <br /><br />
                    Jika Anda memiliki pertanyaan lebih lanjut, jangan ragu untuk menghubungi kami melalui email liquecs@gmail.com atau WhatsApp +62 852-2214-4287
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box id="karir" marginTop={5}>
          <Typography variant="h4" fontWeight="bold">Join Our Team</Typography>
          <Typography variant="h6">Posisi yang Tersedia</Typography>

          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} md={6}>
              <Box sx={{ background: "white", borderRadius: "19px" }}>
                <Box sx={{ background: "#FE8A01", color: "white", borderRadius: "19px", padding: "15px", textAlign: "center" }}>
                  <Typography variant="h5">Staff Online Store</Typography>
                </Box>
                <Box paddingX="15px" paddingBottom="15px">
                  <Typography variant="h6">
                    <ul>
                      <li>Membantu pengelolaan platform online store</li>
                      <li>Mengelola stok produk di sistem</li>
                      <li>Melayani pertanyaan pelanggan secara online</li>
                      <li>Menjalankan tugas sesuai arahan dan job desk yang diberikan</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ background: "white", borderRadius: "19px" }} marginTop={3}>
                <Box sx={{ background: "#FE8A01", color: "white", borderRadius: "19px", padding: "15px", textAlign: "center" }}>
                  <Typography variant="h5">Staff Offline Store</Typography>
                </Box>
                <Box paddingX="15px" paddingBottom="15px">
                  <Typography variant="h6">
                    <ul>
                      <li>Melayani pelanggan di toko fisik</li>
                      <li>Menjaga tampilan produk di toko</li>
                      <li>Membantu pengelolaan stok di toko fisik</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ background: "white", borderRadius: "19px", height: "100%" }}>
                <Box sx={{ background: "#FE8A01", color: "white", borderRadius: "19px", padding: "15px", textAlign: "center" }}>
                  <Typography variant="h5">Social Media Handler</Typography>
                </Box>
                <Box paddingX="15px" paddingBottom="15px">
                  <Typography variant="h6">
                    <ul>
                      <li>Mengelola dan membuat konten kreatif untuk platform media sosial</li>
                      <li>Menyusun strategi dan jadwal posting konten</li>
                      <li>Meningkatkan engagement dan followers melalui sosial media yang aktif</li>
                      <li>Mengganti dan memperbarui profil sosial media sesuai dengan perkembangan merek</li>
                      <li>Melakukan analisis terhadap performa konten dan strategi media sosial</li>
                      <li>Mengirimkan portofolio konten yang pernah dibuat saat melamar</li>
                      <li>Kualifikasi: Memiliki pengalaman dalam manajemen sosial media dan pembuatan konten visual</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ background: "white", borderRadius: "19px", height: "100%" }}>
                <Box sx={{ background: "#FE8A01", color: "white", borderRadius: "19px", padding: "15px", textAlign: "center" }}>
                  <Typography variant="h5">Editor</Typography>
                </Box>
                <Box paddingX="15px" paddingBottom="15px">
                  <Typography variant="h6">
                    <ul>
                      <li>Mengelola dan membuat konten kreatif untuk platform media sosial</li>
                      <li>Menyesuaikan materi visual dengan brand kami. Mengirimkan portofolio hasil edit terbaik Anda saat melamar </li>
                      <li>Kualifikasi: Memiliki pengalaman dalam editing foto/video, dapat bekerja dengan perangkat editing profesional, dan mampu bekerja cepat sesuai deadline.</li>
                      <li>Mengganti dan memperbarui profil sosial media sesuai dengan perkembangan merek</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ background: "white", borderRadius: "19px", height: "100%" }}>
                <Box sx={{ background: "#FE8A01", color: "white", borderRadius: "19px", padding: "15px", textAlign: "center" }}>
                  <Typography variant="h5">Penjahit</Typography>
                </Box>
                <Box paddingX="15px" paddingBottom="15px">
                  <Typography variant="h6">
                    <ul>
                      <li>Melakukan perbaikan/perubahan pada pakaian yang dijual</li>
                      <li>Memastikan kualitas jahitan sesuai dengan standar yang ditetapkan</li>
                      <li>Menyelesaikan rework sesuai dengan waktu yang ditentukan</li>
                      <li>Mampu menjahit dengan detail dan presisi</li>
                      <li>Kualifikasi: Memiliki pengalaman dalam menjahit dan melakukan rework pada pakaian</li>
                      <li>Portofolio: Mohon kirimkan contoh hasil jahitan atau rework yang pernah dikerjakan</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3} marginTop={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" textAlign="center">KUALIFIKASI UMUM</Typography>
              <Typography variant="h6">
                <ul>
                  <li>Minimal pendidikan SMA. Jujur, ulet, bertanggung jawab, disiplin, berkomitmen, tekun, beretika, dan memiliki akhlak yang baik. </li>
                  <li>Bersedia bekerja di BSD, Tangerang. </li>
                  <li>Maaf, untuk saat ini kami tidak menyediakan mess/tempat tinggal.</li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ borderLeft: {xs: "unset", md: "1px solid black"}, borderTop: {xs: "1px solid black", md: "unset"} }}>
              <Typography variant="h4" fontWeight="bold" textAlign="center">SYARAT & KETENTUAN</Typography>
              <Typography variant="h6">
                <ul>
                  <li>Kami mengutamakan etos kerja, kejujuran, dan etika saat bekerja. Apabila Anda merasa memiliki personalitas sesuai dengan apa yang kami cari, mohon untuk mengirimkan CV ke email kami: liquecs@gmail.com</li>
                  <li>Subjek email: [Nama] - [Posisi yang Dilamar] Kami akan menghubungi kandidat yang memenuhi kualifikasi untuk proses wawancara lebih lanjut.</li>
                </ul>
              </Typography>
              <Typography variant="h6" marginTop={2}>
                Terima kasih atas minat Anda bergabung bersama kami! 🌟
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="unset" sx={{ padding: { sm: "unset", md: "unset" }, marginBottom: 10 }} id="contact-us">
        <Box width="100%" position="relative" height="fit-content">
          <Box width="100%" sx={{ display: {xs: "none", md: "block"} }}>
            <img src={liqueContactUs} alt="Lique Background" width="100%"  />
          </Box>

          <Grid container top="0px" alignItems="center" justifyContent="end" height="100%" spacing={5} 
            sx={{ paddingRight: { xs: "unset", md: "60px", lg: "100px" }, position: {sm: "relative", md: "absolute"} }}
          >
            <Grid item xs={12} md={6} xl={5} 
              sx={{ marginTop: {xs: "50px", md: "unset"}, color: {xs: "black", md: "white"} }}
            >
              <Typography variant="h2" fontWeight="bold" textAlign="center">CONTACT US</Typography>
              <Box marginTop={5}>
                <Typography variant="h4" display="flex" alignItems="center" gap={5} sx={{ fontSize: { xs: "1.5em", md: "2.5em"} }}>
                  <InstagramIcon sx={{ fontSize: {xs: 60, md: 80} }} /> @lique.id
                </Typography>
                <Typography variant="h4" display="flex" alignItems="center" gap={5} sx={{ fontSize: { xs: "1.5em", md: "2.5em"} }}>
                  <WhatsAppIcon sx={{ fontSize: {xs: 60, md: 80} }} /> +62 852-2214-4287
                </Typography>
                <Typography variant="h4" display="flex" alignItems="center" gap={5} sx={{ fontSize: { xs: "1.5em", md: "2.5em"} }}>
                  <EmailIcon sx={{ fontSize: {xs: 60, md: 80} }} /> liquecs@gmail.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      
      <Footer />
    </>
  )
}

export default AboutPage;