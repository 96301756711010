import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import axios from "axios";
import Navigation from "./customer/navigation";
import { fontSizeHeader, fontSizeText, fontSizeTextSmall } from "./helper/style";

const containerStyle = {
  backgroundColor: "black",
  color: "white",
  borderRadius: 25,
  boxShadow: "10px 10px 5px grey",
};

const textfieldStyle = {
  input: {
    border: "2px solid black",
    background: "white",
    borderRadius: "4px",
  },
  placeholder: {
    color: "lightgray",
  },
};

const btnSubmit = {
  marginTop: 5,
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
};

const btnBack = {
  marginTop: 5,
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "black",
  border: "2px solid black",
};

function ForgotPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("email", email);
      const response = await axios.post(
        `${backendUrl}/forgotPassword/request`,
        formData
      );
      setEmail("");

      setMsgSuccess(response.data);
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error("error fetching data: ", error);
      setShowError(true);
      setMsgError(error.response.data);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="md" sx={{ marginY: "50px", minHeight: "80vh" }}>
        {showError && (
          <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
            {msgError}
          </Alert>
        )}
        {showSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginBottom: 5 }}>
            {msgSuccess}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h1" variant="h4" sx={{ ...fontSizeHeader }}>
            Lupa Password?
          </Typography>
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12}>
              <Typography sx={{ ...fontSizeText }}>Email</Typography>
              <TextField
                sx={textfieldStyle}
                className="input"
                placeholder="Email"
                autoComplete="off"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...fontSizeTextSmall }}>
                Silahkan isi alamat email mu, kamu akan menerima link untuk me-reset password.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Link
                to={"/login"}
                style={{ color: "#FE8A01", textDecoration: "none" }}
              >
                <Button style={btnBack} fullWidth>
                  Kembali
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button style={btnSubmit} onClick={handleSubmit} fullWidth>
                Konfirmasi
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default ForgotPage;
