import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const TagService = {
  getTagList(flag = "") {
    return axios.get(`${cleanedBackendUrl}/tag`, {
      params: {
        flag: flag,
      },
    });
  },
  getShownTagList(flag = "") {
    return axios.get(`${cleanedBackendUrl}/tag/shown`, {
      params: {
        flag: flag,
      },
    });
  },
  insertTag(title, description, flag, shown, images) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("flag", flag);
    formData.append("shown", shown);
    images.forEach(image => {
      formData.append("images", image.originalFile);
    })

    return axios.post(`${cleanedBackendUrl}/tag`, formData);
  },
  updateTag(id, title, description, shown, images, oldImages) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("shown", shown);
    images.forEach(image => {
      formData.append("images", image.originalFile);
    })
    oldImages.forEach(image => {
      formData.append("oldImages[]", image);
    })

    return axios.patch(`${cleanedBackendUrl}/tag/${id}`, formData);
  },
  deleteTag(id) {
    return axios.delete(`${cleanedBackendUrl}/tag/${id}`)
  }
};

export default TagService;
