import React, { useEffect, useState } from "react";
import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, Chip, Container, Fade, FormControlLabel, Grid, IconButton, Modal, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import Navigation from "./navigation";
import Footer from "./components/footer/footer";
import { fontSizeHeader, fontSizeSubHeader, fontSizeText, fontSizeTextSmall } from "../helper/style";
import { useAuth } from "../authContext";
import EditIcon from '@mui/icons-material/Edit';
import { AddressService } from "../services/customer/addressService";
import CustomerService from "../services/customer/customerService";
import logoSmile from "../assets/smile.png";
import { NumericFormat } from "react-number-format";
import noImage from "../../src/assets/no-image.png";
import { useNavigate } from "react-router-dom";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "70%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  overflowY: "auto",
  overflowX: "hidden"
};

const btnStyle = {
  borderRadius: "12px",
  padding: 10,
  boxShadow: 24,
  color: "black",
  fontWeight: "bold",
  backgroundColor: "#FE8A01",
  textTransform: "none",
};

const btnRincianStyle = {
  width: "100%",
  backgroundColor: "#FE8A01",
  color: "white",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  borderRadius: "100px"
}

const textHereStyle = {
  textDecoration: "underline", 
  fontWeight: "bold",
  cursor: "pointer"
}

const ProfilePage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState([]);

  const [addressList, setAddressList] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [selectedNeighborhood, setselectedNeighborhood] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  const [addressDialog, setAddressDialog] = useState(false);
  const [editAddressId, setEditAddressId] = useState(false);
  const [addAddressDialog, setAddAddressDialog] = useState(false);

  const validate = () => {
    let tempErrors = {};

    if (!selectedAddress) {
      tempErrors.address = "address harus diisi";
    } else if (selectedAddress.length > 255) {
      tempErrors.address = "address maksimal 255 karakter";
    }

    if (!selectedCity) {
      tempErrors.city = "Kota harus diisi";
    }

    if (!selectedProvince) {
      tempErrors.state = "Provinsi harus diisi";
    }

    if (!selectedZipcode) {
      tempErrors.zipcode = "zipcode harus diisi";
    } else if (selectedZipcode.length > 6) {
      tempErrors.zipcode = "zipcode maksimal 6 karakter";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const getStatusColor = (key) => {
    let bg = ''
    let text = ''
  
    if (key === 'DRAFT') {
      bg = '#A0A0A0'
      text = 'black'
    } else if (key === 'WAITING_FOR_PAYMENT') {
      bg = '#FE8A01'
      text = 'white'
    } else if (key === 'PAID') {
      bg = '#5DB7DE'
      text = 'black'
    } else if (key === 'PACKAGED') {
      bg = '#0074CC'
      text = 'white'
    } else if (key === 'ON_DELIVERY') {
      bg = '#9B30FF'
      text = 'white'
    } else if (key === 'DELIVERED') {
      bg = '#19B600'
      text = 'white'
    } else if (key === 'CANCELED') {
      bg = '#E50B0B'
      text = 'white'
    }
  
    return {
      background: bg,
      color: text
    }
  };

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const handleOpenAddressDialog = () => {
    setAddressDialog(true);
  }

  const handleCloseAddressDialog = () => {
    setAddressDialog(false);
  }

  const handleOpenAddAddress = async (address = null) => {
    fetchProvinces();
    if (address != null && address !== undefined) {
      setEditAddressId(address.id);
      fetchCities(address.province);
      fetchSubDistricts(address.province, address.district);
      fetchNeighborhoods(address.province, address.district, address.subdistrict);
      fetchZipCode(address.province, address.district, address.subdistrict);

      setSelectedAddress(address.detail);
      setSelectedProvince(address.province);
      setSelectedCity(address.district);
      setSelectedSubDistrict(address.subdistrict);
      setselectedNeighborhood(address.neighborhood);
      setSelectedZipcode(address.zipCode);
      setIsDefault(address.isDefault);
    } 

    setAddAddressDialog(true);
  }

  const handleCloseAddAddress = () => {
    setAddAddressDialog(false);
    setEditAddressId(null);

    setSelectedAddress("");
    setSelectedProvince(null);
    setSelectedCity(null);
    setSelectedSubDistrict(null);
    setselectedNeighborhood(null);
    setSelectedZipcode(null);
    setIsDefault(false);
  }

  const fetchOrderHistory = async () => {
    try {
      const data = await CustomerService.getOrderHistory(auth?.user.phonenumber);
      const history = data.orderListFiltered.slice(0, 2);

      setHistoryData(history);
      console.log(history);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDataAddress = async () => {
    try {
      const response = await AddressService.fetchDataAddress(auth?.user.phonenumber);
      if (response) {
        const addressList = response.data.addressList
        setAddressList(addressList);

        if (addressList?.length > 0) {
          const defautAddress = addressList.find(address => address.isDefault);
          if (defautAddress) {
            setAddressData(defautAddress);
          } else {
            setAddressData(addressList[0]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProvinces = async () => {
    try {
      const response = await AddressService.fetchProfinces();
      if (response) {
        setProvinces(response.data.provinceList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await AddressService.fetchCities(provinceId);
      if (response) {
        setCities(response.data.districtList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchSubDistricts = async (provinceId, cityId) => {
    try {
      const response = await AddressService.fetchSubDistricts(provinceId, cityId);
      if (response) {
        setSubDistricts(response.data.subdistrictList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchNeighborhoods = async (provinceId, cityId, subDistrictId) => {
    try {
      const response = await AddressService.fetchNeighborhoods(provinceId, cityId, subDistrictId);
      if (response) {
        setNeighborhoods(response.data.neighborhoodList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const fetchZipCode = async (provinceId, cityId, subDistrictId) => {
    try {
      const response = await AddressService.fetchZipCode(provinceId, cityId, subDistrictId);
      if (response) {
        setZipcodes(response.data.zipCodeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const submitAddress = async () => {
    if (!validate()) return;

    try {
      let response = null;
      if (editAddressId != null) {
        response = await AddressService.updateAddress(
          auth?.user.phonenumber,
          editAddressId,
          selectedProvince,
          selectedCity,
          selectedSubDistrict,
          selectedNeighborhood,
          selectedZipcode, 
          selectedAddress,
          isDefault
        )
      } else {
        response = await AddressService.addAddress(
          auth?.user.phonenumber,
          selectedProvince,
          selectedCity,
          selectedSubDistrict,
          selectedNeighborhood,
          selectedZipcode, 
          selectedAddress,
          isDefault
        );

      }

      if (response) {
        fetchDataAddress();

        setAddAddressDialog(false);
        setSelectedAddress(null);
        setSelectedProvince(null);
        setSelectedCity(null);
        setSelectedSubDistrict(null);
        setselectedNeighborhood(null);
        setSelectedZipcode(null);
        setIsDefault(false);
        setEditAddressId(null);
      }
    } catch (error) {
      console.error(error);
      setMsgError(error.response.data.message);
      activateError();
    }
  }

  useEffect(() => {
    if (auth?.user) {
      fetchDataAddress();
      fetchOrderHistory();
    }
  }, [auth])

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ marginY: "50px" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={ShowSuccess}
          autoHideDuration={3000}
          onClose={() => setShowSuccess(false)}
        >
          <SnackbarContent
            sx={{ backgroundColor: "#19B600" }} 
            message={MsgSuccess}/>
        </Snackbar>
  
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={() => setShowError(false)}
        >
          <SnackbarContent 
            sx={{ backgroundColor: "#E50B0B" }} 
            message={msgError}/>
        </Snackbar>

        <Box>
          <Typography variant="h4" fontWeight="bold" sx={{ ...fontSizeHeader }}>MY ACCOUNT</Typography>
          <Typography sx={{ ...fontSizeText }} marginTop={2}>
            Please find below a summary of all your orders, which by clicking into you can obtain product level details for each order. <br />
            Additionally, the order detail page will allow you to check the shipping status of your item(s) and also track your order via the shipping courier with your parcels Tracking ID.
          </Typography>
          <Typography sx={{ ...fontSizeText }} marginTop={2}>
            if you are wanting to create a return for your items, please check our returns policy <span style={textHereStyle} onClick={() => navigate("/about#kebijakan-pengembalian")}>HERE</span> to ensure your items are eligible, 
            before proceeding to get an instant refund <span style={textHereStyle}>HERE</span> or to our returns portal <span style={textHereStyle}>HERE</span>.
          </Typography>
        </Box>

        <Grid container marginTop={2} spacing={2}>
          <Grid item xs={12} md={5}>
            <Box sx={{ paddingY: "10px", textAlign: "center", backgroundColor: "#FE8A01", color: "white", borderRadius: "19px" }}>
              <Typography sx={{ ...fontSizeSubHeader }}>INFORMASI SAYA</Typography>
            </Box>
            <Box sx={{ padding: "20px", borderBottom: "1px solid black" }}>
              <Typography sx={{ ...fontSizeText, fontWeight: "bold" }}>
                DETAIL 
                <span style={{ display: "inline-block", marginLeft: "20px", cursor: "pointer" }}><EditIcon /></span>
              </Typography>
              <Grid container spacing={0.5} marginY={1}>
                <Grid item xs={4}>
                  <Typography sx={{ ...fontSizeText }}>Nama:</Typography> 
                </Grid>
                <Grid item xs={8}>
                <Typography sx={{ ...fontSizeText }}>{auth?.user.name}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={{ ...fontSizeText }}>Email:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ ...fontSizeText }}>{auth?.user.email}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={{ ...fontSizeText }}>Phone:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ ...fontSizeText }}>{auth?.user.phonenumber}</Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ padding: "20px", borderBottom: "1px solid black" }}>
              <Typography sx={{ ...fontSizeText, fontWeight: "bold" }}>
                Daftar Alamat 
                <span style={{ display: "inline-block", marginLeft: "20px", cursor: "pointer" }} onClick={handleOpenAddressDialog}><EditIcon /></span>
              </Typography>
              <Grid container spacing={0.5} marginY={1}>
                {
                  addressData && (
                    <>
                      <Grid item xs={12}>
                        <Typography sx={{ ...fontSizeText }}>{addressData?.detail}</Typography> 
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ ...fontSizeText }}>{`${addressData?.neighborhood}, ${addressData?.subdistrict}`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ ...fontSizeText }}>{`${addressData?.district}, ${addressData?.province}`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ ...fontSizeText }}>{addressData?.zipCode}</Typography> 
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box sx={{ paddingY: "10px", textAlign: "center", backgroundColor: "#FE8A01", color: "white", borderRadius: "19px" }}>
              <Typography sx={{ ...fontSizeSubHeader }}>PESANAN TERBARU SAYA</Typography>
            </Box>
            {
              historyData.map(order => (
                <Box sx={{ padding: "20px", borderBottom: "1px solid black" }} key={order.orderList[0].id}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={5} display="flex" gap="10px"
                      flexDirection="column" justifyContent="space-between"
                    >
                      <Box>
                        <Typography sx={{ ...fontSizeText }}>{order.updatedDate}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ ...fontSizeText, fontWeight: "bold" }}>
                          <NumericFormat
                            value={order.orderList.reduce((sum, item) => sum + item.price, 0)}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"RP "}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Chip 
                          sx={{ ...fontSizeTextSmall }}
                          label={order.orderList?.[0]?.status?.label} 
                          style={getStatusColor(order.orderList?.[0]?.status?.key)}
                        ></Chip>
                      </Box>
                      <Box sx={{ display: { xs: "none", sm: "none", md: "block"} }}>
                        <Button sx={btnRincianStyle}>
                          <Typography sx={{ ...fontSizeText }}>Lihat Rincian</Typography>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Box>
                        {
                          order.orderList.slice(0, 2).map(detail => (
                            <>
                              <img
                                src={
                                  detail.itemList[0].images?.length <= 0 || detail.itemList[0].images == null
                                    ? noImage
                                    : `${imageUrl}${detail.itemList[0].images[0]}`
                                }
                                alt={detail.itemList[0].name}
                                style={{
                                  width: "45%",
                                  aspectRatio: "4 / 5",
                                  objectFit: "cover",
                                  marginRight: "3%"
                                }}
                                key={detail.id}
                              ></img>
                            </>
                          ))
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "blok", md: "none"} }}>
                      <Box>
                        <Button sx={btnRincianStyle}>
                          <Typography sx={{ ...fontSizeText }}>Lihat Rincian</Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            }
            {
              historyData.length <= 0 && (
                <Box>
                  <Typography sx={{ ...fontSizeText, textAlign: "center" }}>Belum ada Pesanan</Typography>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Container>

      <Footer />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={addressDialog}
        onClose={handleCloseAddressDialog}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={addressDialog}>
          <Box sx={styleModal}>
            {
              addressList.length > 0 ? (
                <Box>
                  {
                    addressList.map(address => (
                      <Box position="relative"> 
                        <Box 
                          key={address.id}
                          sx={{
                            backgroundColor: "black",
                            color: "white",
                            borderRadius: "12px",
                            padding: 3,
                            marginY: 2,
                          }}
                        >
                          <Grid container spacing={2} textAlign={"left"} justifyContent="space-between">
                            
                            <Grid item xs={8}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    component="h6"
                                    sx={{ fontWeight: "600" }}
                                  >
                                    {address.detail}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography component="h6">
                                    {address.province}, {address.district},{" "}
                                    {address.subdistrict}, {address.neighborhood},{" "}
                                    {address.zipCode}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>

                        
                        <IconButton sx={{ color: "white", position: "absolute", right: "20px", top: "30%" }} onClick={() => handleOpenAddAddress(address)}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    ))
                  }
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} textAlign="center">
                        <Button
                          onClick={handleOpenAddAddress}
                          style={btnStyle}
                        >
                          New Address
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <img src={logoSmile} alt="Smile Logo" width={100} />
                  <Typography
                    id="spring-modal-title"
                    variant="h6"
                    component="h3"
                  >
                    You haven't added any address yet.
                  </Typography>
                  <Box sx={{ mt: 2 }} textAlign="center">
                    <Button
                      variant="contained"
                      onClick={handleOpenAddAddress}
                      style={btnStyle}
                    >
                      Add Your Location
                    </Button>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={addAddressDialog}
        onClose={handleCloseAddAddress}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={addAddressDialog}>
          <Box sx={styleModal}>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography sx={{ ...fontSizeText }}>Alamat *</Typography>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={selectedAddress}
                    error={!!errors.address}
                    helperText={errors.address}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Jl. Melati Mas..." // Add this line
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ ...fontSizeText }}>Provinsi *</Typography>
                  <Autocomplete
                    options={provinces} 
                    getOptionLabel={(option) => option} 
                    value={selectedProvince}
                    onChange={(event, newValue) => {
                      setSelectedCity(null);
                      setSelectedSubDistrict(null);
                      setselectedNeighborhood(null);
                      setSelectedZipcode(null);

                      fetchCities(newValue);
                      setSelectedProvince(newValue);
                    }}
                    helperText={errors.selectedProvince}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        sx={{ 
                          '& .MuiInputBase-input': {
                            ...fontSizeText
                          },
                        }} 
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ ...fontSizeText }}>Kabupaten/Kota *</Typography>
                  <Autocomplete
                    options={cities}
                    getOptionLabel={(option) => option}
                    value={selectedCity}
                    onChange={(event, newValue) => {
                      setSelectedSubDistrict(null);
                      setselectedNeighborhood(null);
                      setSelectedZipcode(null);

                      fetchSubDistricts(selectedProvince, newValue);
                      setSelectedCity(newValue);
                    }}
                    helperText={errors.selectedCity}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        sx={{ 
                          '& .MuiInputBase-input': {
                            ...fontSizeText
                          },
                        }} 
                      />
                    )}
                    disabled={selectedProvince === null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ ...fontSizeText }}>Kecamatan *</Typography>
                  <Autocomplete
                    options={subDistricts} 
                    getOptionLabel={(option) => option} 
                    value={selectedSubDistrict}
                    onChange={(event, newValue) => {
                      setselectedNeighborhood(null);
                      setSelectedZipcode(null);

                      fetchNeighborhoods(selectedProvince, selectedCity, newValue);
                      fetchZipCode(selectedProvince, selectedCity, newValue)
                      setSelectedSubDistrict(newValue);
                    }}
                    helperText={errors.selectedProvince}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        sx={{ 
                          '& .MuiInputBase-input': {
                            ...fontSizeText
                          },
                        }} 
                      />
                    )}
                    disabled={selectedCity === null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ ...fontSizeText }}>Kelurahan *</Typography>
                  <Autocomplete
                    options={neighborhoods}
                    getOptionLabel={(option) => option}
                    value={selectedNeighborhood}
                    onChange={(event, newValue) =>
                      setselectedNeighborhood(newValue)
                    }
                    helperText={errors.selectedCity}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        sx={{ 
                          '& .MuiInputBase-input': {
                            ...fontSizeText
                          },
                        }} 
                      />
                    )}
                    disabled={selectedSubDistrict === null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ ...fontSizeText }}>Kode Pos *</Typography>
                  <Autocomplete
                    options={zipcodes}
                    getOptionLabel={(option) => option}
                    value={selectedZipcode}
                    onChange={(event, newValue) =>
                      setSelectedZipcode(newValue)
                    }
                    helperText={errors.selectedCity}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        sx={{ 
                          '& .MuiInputBase-input': {
                            ...fontSizeText
                          },
                        }} 
                      />
                    )}
                    disabled={selectedSubDistrict === null}
                  />
                </Grid>
                <Grid item xs={12} md={6} alignSelf="center">
                  <FormControlLabel control={<Checkbox checked={isDefault} onChange={(_event, checked) => setIsDefault(checked)}/>} 
                    label="Tetapkan sebagai default" sx={{ '& .MuiTypography-root': {...fontSizeText} }}/>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={submitAddress}
                    fullWidth
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ProfilePage;