import { Grid, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ItemCarousel = ({children, title = ""}) => {
  return (
    <>
      <Grid container marginTop={5}>
        {
          title && (
            <>
              <Grid item xs={12} textAlign="center" marginBottom={0}>
                <Typography variant="h4" textTransform="uppercase" sx={{ fontSize: { xs: "1em", md: "2em" } }}>
                  <b>{title}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={0}>
                <hr style={{ height: '1px', background: 'black', margin: "4px" }}></hr>
              </Grid>
            </>
          )
        }
        <Grid item xs={12}>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 900
                },
                items: 4,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 599,
                  min: 0
                },
                items: 3,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 899,
                  min: 600
                },
                items: 3,
                partialVisibilityGutter: 30
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {children}
          </Carousel>
        </Grid>
      </Grid>
    </>
  )
}

export default ItemCarousel