import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

export const AddressService = {
  fetchProfinces() {
    return axios.get(`${cleanedBackendUrl}/address/provinces`);
  },
  fetchCities(provinceId) {
    return axios.get(
      `${cleanedBackendUrl}/address/districts?province=${provinceId}`
    );
  },
  fetchSubDistricts(provinceId, cityId) {
    return axios.get(
      `${cleanedBackendUrl}/address/subdistricts?province=${provinceId}&district=${cityId}`
    );
  },
  fetchNeighborhoods(provinceId, cityId, subDistrictId) {
    return axios.get(
      `${cleanedBackendUrl}/address/neighborhoods?province=${provinceId}&district=${cityId}&subdistrict=${subDistrictId}`
    );
  },
  fetchZipCode(provinceId, cityId, subDistrictId) {
    return axios.get(
      `${cleanedBackendUrl}/address/zip-codes?province=${provinceId}&district=${cityId}&subdistrict=${subDistrictId}`
    );
  },
  calculateShippingCost(originCityId, selectedCity, weight) {
    return axios.get(
      `${backendUrl}/customer/api/rajaongkir/cost?originType=city&origin=${originCityId}&destinationType=city&destination=${selectedCity}&weight=${weight}`
    );
  },
  fetchDataAddress(phoneNumber) {
    return axios.get(`${cleanedBackendUrl}/customer/${phoneNumber}/address`);
  },
  addAddress(
    phoneNumber,
    province,
    city,
    subDistrict,
    neighborhood,
    zipCode,
    address,
    isDefault
  ) {
    return axios.post(`${cleanedBackendUrl}/customer/${phoneNumber}/address`, {
      province: province,
      district: city,
      subdistrict: subDistrict,
      neighborhood: neighborhood,
      zipCode: zipCode,
      detail: address,
      isDefault: isDefault,
    });
  },
  updateAddress(
    phoneNumber,
    addressId,
    province,
    city,
    subDistrict,
    neighborhood,
    zipCode,
    address,
    isDefault
  ) {
    return axios.put(`${cleanedBackendUrl}/customer/${phoneNumber}/address/${addressId}`, {
      province: province,
      district: city,
      subdistrict: subDistrict,
      neighborhood: neighborhood,
      zipCode: zipCode,
      detail: address,
      isDefault: isDefault,
    });
  },
};
