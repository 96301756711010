import {HashRouter as Router, Navigate, Route, Routes, useNavigate,} from "react-router-dom";
import "./App.css";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AuthProvider} from "./authContext";

import LoginPage from "./login";
import RegisterPage from "./register";
import PrivateRoute from "./privateRoute";

import ClockInManager from "./manager/clockIn";
import ClockOutManager from "./manager/clockOut";
import PresensiManager from "./manager/presensi";
import DataKaryawan from "./manager/dataKaryawan";
import GajiKaryawan from "./manager/gajiKaryawan";
import ManageEmployee from "./manager/manageEmployee";
import ReviewStokManager from "./manager/reviewStok";
import TipeStokManager from "./manager/tipeStok";
import PengirimanManager from "./manager/pengiriman";
import ReviewOrderDeliveryManager from "./manager/reviewOrderDelivery";
import PemesananManager2 from "./manager/pemesanan2";
import LiveManager2 from "./manager/live2";

import ReviewStokAdmin from "./admin/reviewStok";
import TipeStokAdmin from "./admin/tipeStok";
import PemesananAdmin from "./admin/pemesanan";
import PemesananAdmin2 from "./admin/pemesanan2"
import PengirimanAdmin from "./admin/pengiriman";
import ReviewOrderDeliveryAdmin from "./admin/reviewOrderDelivery";
import Live from "./admin/live";
import Live2 from "./admin/live2";
import Resi from "./admin/resi";
import ForgotPage from "./forgot";
import ResetPasswordPage from "./resetPassword";

import ReviewStokSupervisor from "./supervisor/reviewStok";
import TipeStokSupervisor from "./supervisor/tipeStok";
import PemesananSupervisor from "./supervisor/pemesanan";
import ClockInSupervisor from "./supervisor/clockIn";
import ClockOutSupervisor from "./supervisor/clockOut";
import PengirimanSupervisor from "./supervisor/pengiriman";
import ReviewOrderDeliverySupervisor from "./supervisor/reviewOrderDelivery";
import PresensiSupervisor from "./supervisor/presensi";

import DashboardCustomer from "./customer/dashboardCustomer";
import CheckoutPage from "./customer/checkoutPage";
import PaymentPage from "./customer/paymentPage";
import HistoryPage from "./customer/historyPage";
import HomePage from "./customer/homePage";
import ItemDetailPage from "./customer/itemDetailPage";
import MoreItemsPage from "./customer/moreItemPage";
import CartPage from "./customer/cartPage";
import WishlistPage from "./customer/wishlistPage";
import ReviewTag from "./manager/tag";
import ReviewBanner from "./manager/banner";
import ReviewRefund from "./manager/reviewRefund";
import HomePage2 from "./customer/homePage2";
import ItemDetailPage2 from "./customer/itemDetailPage2";
import CheckoutPage2 from "./customer/checkoutPage2";
import AboutPage from "./customer/aboutPage";
import ProfilePage from "./customer/profilePage";

function AppWrapper() {
  const navigate = useNavigate();
  return (
    <AuthProvider navigate={navigate}>
      <Routes>
        <Route path="/" element={<Navigate to="/home"/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/register" element={<RegisterPage/>}/>
        <Route path="/forgot" element={<ForgotPage/>}/>
        <Route path="/resetPassword/verify" element={<ResetPasswordPage/>}/>
        <Route path="/resetPassword/reset" element={<ResetPasswordPage/>}/>
        
        {/* <Route path="/home" element={<HomePage/>}/> */}
        <Route path="/home" element={<HomePage2/>}/>
        <Route path="/items/tag/:tag" element={<MoreItemsPage/>}/>
        <Route path="/item/:id" element={<ItemDetailPage2/>}/>
        <Route path="/about" element={<AboutPage />} />

        {/* ADMIN */}
        <Route element={<PrivateRoute roles={[1]}/>}>
          <Route path="/admin/stok/reviewStok" element={<ReviewStokAdmin/>}/>
          <Route path="/admin/stok/tipeBarang" element={<TipeStokAdmin/>}/>
          <Route path="/admin/orderDelivery/live" element={<Live/>}/>
          <Route path="/admin/orderDelivery/live2" element={<Live2/>}/>
          <Route path="/admin/orderDelivery/no_resi" element={<Resi/>}/>
          <Route
            path="/admin/orderDelivery/pemesanan"
            element={<PemesananAdmin/>}
          />
          <Route
            path="/admin/orderDelivery/pemesanan2"
            element={<PemesananAdmin2/>}
          />
          <Route
            path="/admin/orderDelivery/pengiriman"
            element={<PengirimanAdmin/>}
          />
          <Route
            path="/admin/orderDelivery/reviewOrderDelivery"
            element={<ReviewOrderDeliveryAdmin/>}
          />
        </Route>

        {/* SUPERVISOR */}
        <Route element={<PrivateRoute roles={[2]}/>}>
          <Route
            path="/supervisor/karyawan/presensi"
            element={<PresensiSupervisor/>}
          />
          <Route
            path="/supervisor/karyawan/presensi/clockin"
            element={<ClockInSupervisor/>}
          />
          <Route
            path="/supervisor/karyawan/presensi/clockout"
            element={<ClockOutSupervisor/>}
          />
          <Route
            path="/supervisor/stok/reviewStok"
            element={<ReviewStokSupervisor/>}
          />
          <Route
            path="/supervisor/stok/tipeBarang"
            element={<TipeStokSupervisor/>}
          />
          <Route
            path="/supervisor/orderDelivery/pemesanan"
            element={<PemesananSupervisor/>}
          />
          <Route
            path="/supervisor/orderDelivery/pengiriman"
            element={<PengirimanSupervisor/>}
          />
          <Route
            path="/supervisor/orderDelivery/reviewOrderDelivery"
            element={<ReviewOrderDeliverySupervisor/>}
          />
        </Route>

        {/* MANAGER */}
        <Route element={<PrivateRoute roles={[3]}/>}>
          <Route
            path="/manager/karyawan/presensi"
            element={<PresensiManager/>}
          />
          <Route
            path="/manager/karyawan/presensi/clockin"
            element={<ClockInManager/>}
          />
          <Route
            path="/manager/karyawan/presensi/clockout"
            element={<ClockOutManager/>}
          />
          <Route
            path="/manager/karyawan/dataKaryawan"
            element={<DataKaryawan/>}
          />
          <Route
            path="/manager/karyawan/gajiKaryawan"
            element={<GajiKaryawan/>}
          />
          <Route
            path="/manager/karyawan/manageEmployee"
            element={<ManageEmployee/>}
          />
          <Route
            path="/manager/stok/reviewStok"
            element={<ReviewStokManager/>}
          />
          <Route
            path="/manager/stok/tipeBarang"
            element={<TipeStokManager/>}
          />
          <Route
            path="/manager/tag"
            element={<ReviewTag/>}
          />
          <Route
            path="/manager/banner"
            element={<ReviewBanner/>}
          />
          <Route
            path="/manager/orderDelivery/pengiriman"
            element={<PengirimanManager/>}
          />
          <Route
            path="/manager/orderDelivery/reviewOrderDelivery"
            element={<ReviewOrderDeliveryManager/>}
          />
           <Route path="/manager/orderDelivery/live2" element={<LiveManager2/>}/>
          <Route
            path="/manager/orderDelivery/pemesanan2"
            element={<PemesananManager2/>}
          />
          <Route 
            path="/manager/orderDelivery/refunds"
            element={<ReviewRefund />}
          />
        </Route>

        {/* CUSTOMER */}
        <Route element={<PrivateRoute roles={[4]}/>}>
          {/* <Route path="/customer" element={<HomePage/>}/>
          <Route path="/customer/home" element={<HomePage/>}/>
          <Route path="/customer/items/:tag" element={<MoreItemsPage/>}/>
          <Route path="/customer/item/:id" element={<ItemDetailPage/>}/> */}
          <Route path="/customer/dashboard" element={<DashboardCustomer/>}/>
          <Route path="/customer/checkoutPage" element={<CheckoutPage2/>}/>
          {/* <Route path="/customer/checkoutPage2" element={<CheckoutPage2/>}/> */}
          <Route path="/customer/checkoutPage/:id" element={<CheckoutPage2/>}/>
          <Route path="/customer/paymentPage" element={<PaymentPage/>}/>
          <Route path="/customer/historyPage" element={<HistoryPage/>}/>
          <Route path="/customer/cart" element={<CartPage/>}/>
          <Route path="/customer/wishlist" element={<WishlistPage/>}/>
          <Route path="/customer/profile" element={<ProfilePage />} />
        </Route>
        <Route path="/checkout" element={<CheckoutPage/>}/>
        {/* <Route path="/customer/dashboard" element={<DashboardCustomer />} />
        <Route path="/customer/checkoutPage" element={<CheckoutPage />} />
        <Route path="/customer/paymentPage" element={<PaymentPage />} /> */}
      </Routes>
    </AuthProvider>
  );
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <AppWrapper/>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
