import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { Alert, Box, Grid, InputAdornment } from "@mui/material";
import { useAuth } from "./authContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoShow from "./assets/EyeClose.svg";
import logoHide from "./assets/EyeOpen.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Navigation from "./customer/navigation";
import { fontSizeSubHeader, fontSizeText } from "./helper/style";

const containerStyle = {
  backgroundColor: "black",
  color: "white",
  borderRadius: 25,
  boxShadow: "10px 10px 5px grey",
};

const textfieldStyle = {
  input: {
    border: "2px solid black",
    background: "white",
    borderRadius: "4px",
  },
  placeholder: {
    color: "lightgray",
  },
};

const triangleDown = {
  width: 0,
  height: 0,
  borderLeft: "40px solid transparent",
  borderRight: "40px solid transparent",
  borderTop: "40px solid black",
  position: "absolute",
  left: "40%"
}

const passwordTextfieldStyle = {
  '& .MuiInputBase-root' : {
    paddingRight: "unset",
  },
  input: {
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
    borderLeft: "2px solid black",
    background: "white",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  placeholder: {
    color: "lightgray",
  }
};

const passwordToggleStyle = {
  borderTop: "2px solid black",
  borderBottom: "2px solid black",
  borderRight: "2px solid black",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  background: "white",
  height: "56px",
  maxHeight: "unset",
  marginLeft: "unset",
  paddingLeft: "8px",
  paddingRight: "15px",
}

const btnLogin = {
  marginTop: "50px",
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  width: "200px",
  maxWidth: "100%"
};

function LoginPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setmsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [param, setParam] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { login } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderidFromQuery = params.get("orderid");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleRegister = () => {
    if (param) {
      navigate(`/register?orderid=${orderidFromQuery}`);
    } else {
      navigate(`/register`)
    }
  }

  useEffect(() => {
    if (orderidFromQuery != null) {
      setParam(orderidFromQuery);
    } else {
      setParam("");
    }
  }, [orderidFromQuery]);

  useEffect(() => {
    const storedMessage = localStorage.getItem("berhasilRegister");
    if (storedMessage) {
      setShowSuccess(true);
      setmsgSuccess(storedMessage);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      localStorage.removeItem("berhasilRegister");
    }
  }, []);

  const validate = () => {
    let tempErrors = {};
    if (!username) {
      tempErrors.username = "Username harus diisi";
    }
    if (!password) {
      tempErrors.password = "Password harus diisi";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("orderid", orderidFromQuery);
      try {
        const response = await axios.post(`${backendUrl}/login`, formData);
        console.log(response.data);
        if (response.data.customer) {
          if (orderidFromQuery != null && response.data.cekPhoneOrder) {
            localStorage.setItem("orderid", orderidFromQuery);
          }
          login(response.data.customer);
        } else {
          login(response.data);
        }
      } catch (error) {
        console.error("error fetching data: ", error);
        setShowError(true);
        setMsgError("Invalid Username or Password");
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }
  };

  return (
    <>
      <Navigation />
      
      <Container component="main" maxWidth="md" sx={{ marginY: "50px", minHeight: "80vh" }}>
        {showSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginBottom: 5 }}>
            {msgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginBottom={5}>
          <Grid item xs={6} sx={{ textAlign: "center", background: "white", color: "black" }}>
            <Box width="100%" height="100%" onClick={handleRegister} sx={{ cursor: "pointer", paddingY: "10px" }}>
              <Typography sx={{ ...fontSizeText }}>BARU DI LIQUE STORE?</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center", background: "black", color: "white" }}>
            <Box width="100%" height="100%" sx={{ paddingY: "10px", position: "relative" }}>
              <Typography sx={{ ...fontSizeText }}>SUDAH PUNYA AKUN?</Typography>
              {/* <div style={triangleDown}></div> */}
            </Box>
          </Grid>
        </Grid>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* <Typography component="h1" variant="h4">
            Login
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography>Don't have an account?</Typography>&nbsp;&nbsp;&nbsp;
            <Typography sx={{ color: "#FE8A01" }}>
              {param ? (
                // <a
                //   href={`/register?orderid=${orderidFromQuery}`}
                //   style={{ color: "#FE8A01", textDecoration: "none" }}
                // >
                //   Register here
                // </a>
                <Link
                  style={{ color: "#FE8A01", textDecoration: "none" }}
                  to={`/register?orderid=${orderidFromQuery}`}
                >
                  Register here
                </Link>
              ) : (
                <Link
                  style={{ color: "#FE8A01", textDecoration: "none" }}
                  to={"/register"}
                >
                  Register here
                </Link>
              )}
            </Typography>
          </Box> */}
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <Typography sx={{ ...fontSizeText }}>Username / No Telepon / Email</Typography>
              <TextField
                sx={textfieldStyle}
                className="input"
                placeholder="Username / No Telepon / Email"
                value={username}
                autoComplete="off"
                fullWidth
                helperText={errors.username}
                FormHelperTextProps={{ sx: { color: "red" } }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...fontSizeText }}>Password</Typography>
              <TextField
                sx={passwordTextfieldStyle}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                fullWidth
                helperText={errors.password}
                FormHelperTextProps={{ sx: { color: "red" } }}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={passwordToggleStyle}>
                      <div
                        onClick={handleClickShowPassword}
                        style={{ cursor: "pointer", marginTop: "3px" }}
                      >
                        { showPassword ? <VisibilityOffIcon sx={{ color: "black" }} /> : <VisibilityIcon sx={{ color: "black" }} />}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={2} class="noPad">
              <div class="show" id="show" onClick={handleClickShowPassword}>
                <img src={logoShow} />
              </div>
              <div class="hide" id="hide" onClick={handleClickHidePassword}>
                <img src={logoHide} />
              </div>
            </Grid> */}
            <Grid item xs={12} textAlign="center">
              <Button style={btnLogin} fullWidth onKeyDown={handleSubmit} onClick={handleSubmit}>
                Login
              </Button>
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: "gray",
              marginTop: 3,
              textDecoration: "none",
              textAlign: "center"
            }}
            component={Link}
            to="/forgot"
          >
            Lupa Password?
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default LoginPage;
