import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../authContext";
import { useNavigate } from "react-router-dom";
import WishlistService from "../services/customer/wishlistService";
import Navigation from "./navigation";
import DeleteIcon from "@mui/icons-material/Delete";
import noImage from "../../src/assets/no-image.png";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import Footer from "./components/footer/footer";
import ItemCard from "./components/item/itemCard";

const WishlistPage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const imageUrl = "https://api.liquestore.com/assets/items/";

  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [wishlistArr, setWishlistArr] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };

  const handleOpenDelete = (cartId) => {
    setDeleteId(cartId);
    setOpenDelete(true);
  };

  const getWishlist = async () => {
    try {
      const response = await WishlistService.getWishlist(auth?.user.id);
      if (response) {
        setWishlistArr(response.data.wishlistList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteWishlist = async () => {
    setLoadingDelete(true);
    try {
      const response = await WishlistService.deleteWishlistById(deleteId);
      if (response) {
        setWishlistArr(
          wishlistArr.filter((wishlist) => wishlist.id !== deleteId)
        );
        setMsgSuccess("Delete Wishlist Success");
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    } finally {
      setLoadingDelete(false);
      handleCloseDelete();
    }
  };

	const addToCart = async (wishlistId) => {
		try {
      const response = await WishlistService.addToCart(wishlistId);
      if (response) {
        setOpenSuccessSnack(true)
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    }
	}

  useEffect(() => {
    getWishlist();
		window.scrollTo(0, 0);
  }, []);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "center",
    overflowY: "auto",
  };
	
  const btnPrimaryStyle = {
    borderRadius: "15px",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: "5px 10px",
    textTransform: "none",
    fontSize: "1em",
  };

	const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/cart")}
      >
        Open Cart
      </Button>
    </>
  );

  return (
    <>
      <Navigation />

      <Container component="main" sx={{ minHeight: "80vh" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} columnSpacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h4" fontWeight="bold" color="black">
              WISHLIST
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          {wishlistArr.map((wishlist) => (
            <Grid item xs={6} md={4} lg={3} sx={{ padding: "15px" }} key={wishlist.id}>
              <ItemCard key={wishlist.item.id} item={wishlist.item} />
              {/* <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <div style={{ width: "100%", aspectRatio: "1 / 1", cursor: 'pointer' }} onClick={() => navigate(`/item/${wishlist.item.id}`)}>
                    <img
                      src={
                        wishlist.item.images?.length <= 0 ||
                        wishlist.item.images == null
                          ? noImage
                          : `${imageUrl}${wishlist.item.images[0]}`
                      }
                      alt={wishlist.item.name}
                      style={{
                        width: "100%",
                        aspectRatio: "1 / 1",
                        objectFit: "cover",
                      }}
                    ></img>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <Typography variant="h5">{wishlist.item.name}</Typography>
                    <Typography variant="p" style={{ marginBottom: "10px" }}>
                      Size: {wishlist.item.size}
                    </Typography>
                  </div>
                  <Typography variant="h5" color="#FE8A01" fontWeight="bold">
                    <NumericFormat
                      value={wishlist.item.defaultPrice}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"Rp. "}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  marginTop={1}
                >
                  <IconButton
                    aria-label="remove"
                    onClick={() => handleOpenDelete(wishlist.id)}
                  >
                    <DeleteIcon style={{ color: "#E50B0B" }} />
                  </IconButton>
                  <Button style={btnPrimaryStyle} onClick={() => addToCart(wishlist.id)}>
                    + Add to Cart
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer />

			<Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={action}
          message="Berhasil menambahkan ke keranjang"/>
      </Snackbar>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={styleModal}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin menghapus produk ini dari wishlist?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#E50B0B", color: "white" }}
                onClick={deleteWishlist}
                disabled={loadingDelete}
              >
                Hapus
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseDelete}
                sx={{
                  ml: 2,
                  backgroundColor: "#FE8A01",
                  color: "white",
                }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default WishlistPage;
