import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "./navigation";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import noImage from "../../src/assets/no-image.png";
import { useAuth } from "../authContext";
import CartService from "../services/customer/cartService";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "./components/footer/footer";

export default function CartPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [cartArr, setCartArr] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [cashback, setCashback] = useState(0);
  const [discountCode, setDiscountCode] = useState(null);
  const [openProceed, setOpenProceed] = useState(false);
  const [loadingProceed, setLoadingProceed] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };

  const handleOpenDelete = (cartId) => {
    setDeleteId(cartId);
    setOpenDelete(true);
  };

  const handleCloseProceed = () => {
    setOpenProceed(false);
  };

  const handleOpenProceed = (cartId) => {
    setOpenProceed(true);
  };

  const styleModalDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "center",
    overflowY: "auto",
  };

  const btnPrimaryStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnWhiteStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "white",
    color: "#FE8A01",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnCheckoutStyle = {
    width: "100%",
    backgroundColor: "#FE8A01",
    color: "white",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  }

  const btnDisabledStyle = {
    width: "100%",
    backgroundColor: "#dddddd",
    color: "white",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  };

  const btnRedStyle = {
    borderRadius: "15px",
    width: "100px",
    backgroundColor: "#E50B0B",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const checkboxStyle = {
    color: "#FE8A01",
    "&.MuiChecked": {
      color: "#FE8A01",
    },
  };

  const qtyBtnStyle = {
    width: "30px",
    height: "30px",
    color: "white",
    background: "#FE8A01",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    cursor: "pointer",
    border: "none"
  }

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const getCartList = async (userId) => {
    try {
      const result = await CartService.getCartList(userId);
      if (result) {
        setCartArr(result.data.cartList);
        calculatePrice();
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    }
  };

  const calculatePrice = () => {
    let price = 0;
    cartArr.forEach((cart) => {
      if (cart.checked) {
        price += cart.item.defaultPrice * cart.quantity;
      }
    });

    setTotalPrice(price);
  };

  const changeCheckedStatus = async (cart) => {
    try {
      const checked = !cart.checked;
      cart.checked = checked;
      calculatePrice();

      await CartService.changeCartStatus(cart.id, checked, cart.quantity);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCart = async (cartId) => {
    setLoadingDelete(true);
    try {
      const result = await CartService.deleteCart(cartId);
      if (result) {
        setCartArr(cartArr.filter((cart) => cart.id !== cartId));
        calculatePrice();
        setMsgSuccess("Delete cart success");
      }
    } catch (error) {
      console.error(error);
      setMsgError("Something went wrong");
    } finally {
      setLoadingDelete(false);
      handleCloseDelete();
    }
  };

  const proceedToPayment = async () => {
    setLoadingProceed(true);
    try {
      const id = [];
      cartArr.forEach((cart) => {
        if (cart.checked) id.push(cart.id);
      });

      
      const cart = await CartService.getCartList(auth?.user.id, 0);
      if (cart) {
        const cartList = cart.data.cartList;
        for (const cartData of cartList) {
          if (cartData.checked) {
            if (parseInt(cartData.quantity) > parseInt(cartData.variant?.quantity || 0)) {
              setMsgError(`Stok item ${cartData.item.name} tidak mencukupi. Stok tersedia: ${parseInt(cartData.variant?.quantity || 0)}`)
              activateError();
              return;
            }
          }
        }
      }

      const result = await CartService.proceedToPayment(id);
      if (result) {
        // setCartArr(cartArr.filter((cart) => cart.id !== deleteId));
        // calculatePrice();
        const orderId = result.data?.[0].id
        navigate(`/customer/checkoutPage/${orderId}`);
        getCartList(auth?.user.id);
      }
    } catch (error) {
      if (error.response.status === 406) {
        setMsgError("Ada item yang stok nya tidak mencukupi, silahkan refresh dan coba lagi");
        activateError();
      } else {
        console.error(error);
        setMsgError("Something went wrong");
      }
    } finally {
      setLoadingProceed(false);
      handleCloseProceed();
    }
  };

  const handleQuantityChange = (value, id) => {
    if (!value) {
      value = '1';
    }

    if (!isNaN(parseInt(value))) {
      if (parseInt(value) < 1) {
        value = '1';
      }
    }

    let number = value.replace(/\D/g,'');
    const cart = cartArr.find(c => c.id === id);
    if (cart.variant) {
      if (number > cart.variant.quantity) {
        number = cart.variant.quantity
        setMsgError(`Maksimum beli ${cart.variant.quantity}`);
        activateError();
      }
    }

    setCartArr(prevData => {
      return prevData.map( cart => cart.id === id ? { ...cart, quantity: number} : cart )
    });

    calculatePrice();
    updateCartQty(id, number);
  }

  const handleAddQuantity = (id) => {
    let quantity = null;
    setCartArr(prevData => {
      return prevData.map( cart => {
        if (cart.id === id ) {
          quantity = cart.quantity + 1;

          if (cart.variant) {
            if (quantity > cart.variant.quantity) {
              quantity = cart.variant.quantity;
              setMsgError(`Maksimum beli ${cart.variant.quantity}`);
              activateError();
            }
          }

          return { ...cart, quantity: quantity};
        } else {
          return cart;
        }
      })
    });

    calculatePrice();

    if (quantity) {
      updateCartQty(id, quantity);
    }
  }

  const handleMinusQuantity = (id) => {
    let quantity = null;
    setCartArr(prevData => {
      return prevData.map( cart => {
        if (cart.id === id) {
          quantity = cart.quantity - 1 <= 0 ? 1 : cart.quantity - 1;
          return { 
            ...cart, 
            quantity: cart.quantity - 1 <= 0 ? 1 : cart.quantity - 1
          };
        } else {
          return cart;
        }
      })
    });

    calculatePrice();

    if (quantity) {
      updateCartQty(id, quantity);
    }
  }

  const updateCartQty = async (id, quantity) => {
    const cart = cartArr.find(cart => cart.id === id);
    await CartService.changeCartStatus(id, cart.checked, quantity)
  }

  useEffect(() => {
    getCartList(auth?.user.id);
    window.scrollTo(0, 0);
  }, [auth]);

  useEffect(() => {
    calculatePrice();
  }, [cartArr]);

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ marginBottom: "50px" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} columnSpacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h4" fontWeight="bold" color="#black" textAlign="center">
              Keranjang Belanja
            </Typography>
          </Grid>
        </Grid>

        <Grid container position="relative" minHeight="80vh">
          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={9} xl={9} marginBottom={2}>
              <Box>
                <Grid container height="60px" backgroundColor="#FE8A01" padding="15px" >
                  <Grid item xs={12} md={5} display="flex" alignItems="center">
                    <Typography color="white" variant="h5" fontWeight="bold">Item</Typography>
                  </Grid>
                  <Grid item xs={12} md={7} sx={{ display: { xs: 'none', md: 'block'} }}>
                    <Grid container height="100%">
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography color="white" variant="h5" fontWeight="bold">Harga</Typography>
                      </Grid>
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography color="white" variant="h5" fontWeight="bold">Jumlah</Typography>
                      </Grid>
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography color="white" variant="h5" fontWeight="bold">Subtotal</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container backgroundColor="white" padding="15px">
                  {
                    cartArr.map((cart) => (
                      <Grid container xs={12} key={cart.id} marginTop={2} columnSpacing={1}>
                        <Grid item xs={12} md={5} display="flex" alignItems="center">
                          <Grid container columnSpacing={1}>
                            <Grid item xs={2} md={2} xl={1} display="flex" alignItems="center">
                              <Checkbox
                                style={checkboxStyle}
                                defaultChecked={cart.checked}
                                onChange={() => changeCheckedStatus(cart)}
                              ></Checkbox>
                            </Grid>
                            <Grid item xs={5}>
                              <img
                                src={
                                  cart.item.images?.length <= 0 || cart.item.images == null
                                    ? noImage
                                    : `${imageUrl}${cart.item.images[0]}`
                                }
                                alt={cart.item.name}
                                style={{
                                  width: "100%",
                                  aspectRatio: "4 / 5",
                                  objectFit: "cover",
                                }}
                              ></img>
                            </Grid>
                            <Grid itemm xs={5} md={5} xl={6} paddingLeft="10px" display="flex" alignItems="center">
                              <Grid container rowSpacing={1}>
                                <Grid item xs={12}>
                                  {
                                    cart.item?.brand && (
                                      <>
                                        <img 
                                          src={cart.item?.brand.images == null ? noImage : `${imageUrl}${cart.item.brand.images[0]}`}
                                          alt={cart.item?.brand.title}
                                          style={{ width: "50%", aspectRatio: "6 / 2", objectFit: "contain" }}
                                        />
                                      </>
                                    )
                                  }
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="p" fontWeight="bold">{cart.item?.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="p">Size: {cart.variant?.size}</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none'} }}>
                                  <Typography variant="p" fontWeight="bold">
                                    <NumericFormat
                                      value={cart.item.defaultPrice}
                                      displayType={"text"}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      prefix={"Rp. "}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none'} }}>
                                  <Box display="flex" alignItems="center" gap="5px"> 
                                    <button style={qtyBtnStyle} onClick={() => handleMinusQuantity(cart.id)}>-</button>
                                    <TextField
                                      sx={{ width: "50px", padding: "1px", textAlign: "center" }}
                                      autoComplete="off"
                                      value={cart.quantity}
                                      min="1"
                                      onChange={(e) => handleQuantityChange(e.target.value, cart.id)}
                                    />
                                    <button style={qtyBtnStyle} onClick={() => handleAddQuantity(cart.id)}>+</button>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sx={{ alignSelf: "flex-end" }}>
                                  <span style={{ cursor: "pointer" }} onClick={() => deleteCart(cart.id)}>
                                    <Typography variant="p" fontWeight="bold" 
                                      sx={{ textDecoration: "underline" }}
                                    >
                                      Hapus
                                    </Typography>
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={7} sx={{ display: { xs: 'none', md: 'block'} }}>
                          <Grid container height="100%">
                            <Grid item xs={4} display="flex" alignItems="center">
                              <Typography variant="p" fontWeight="bold">
                                <NumericFormat
                                  value={cart.item.defaultPrice}
                                  displayType={"text"}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix={"Rp. "}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={4} display="flex" alignItems="center">
                              <Box display="flex" alignItems="center" gap="5px"> 
                                <button style={qtyBtnStyle} onClick={() => handleMinusQuantity(cart.id)}>-</button>
                                <TextField
                                  sx={{ width: "50px", padding: "1px", textAlign: "center" }}
                                  autoComplete="off"
                                  value={cart.quantity}
                                  min="1"
                                  onChange={(e) => handleQuantityChange(e.target.value, cart.id)}
                                />
                                <button style={qtyBtnStyle} onClick={() => handleAddQuantity(cart.id)}>+</button>
                              </Box>
                            </Grid>
                            <Grid item xs={4} display="flex" alignItems="center">
                              <Typography variant="p">
                                <NumericFormat
                                  value={cart.item.defaultPrice * cart.quantity}
                                  displayType={"text"}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix={"Rp. "}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} xl={3} position="relative" marginBottom={2}>
              <Box position="sticky" top="100px">
                <Box height="60px" backgroundColor="#FE8A01" display="flex" justifyContent="center" alignItems="center">
                  <Typography color="white" fontWeight="bold" variant="h5">Ringkasan Belanja</Typography>
                </Box>
                <Box backgroundColor="white" padding="15px">
                  <Grid container rowSpacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="p">Subtotal</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="p" display="flex" justifyContent="end">
                        <NumericFormat value={totalPrice} displayType={"text"}
                          thousandSeparator="." decimalSeparator="," prefix={"Rp. "} />
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="p">Discount</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="p" display="flex" justifyContent="end">
                        <NumericFormat value={discount} displayType={"text"}
                          thousandSeparator="." decimalSeparator="," prefix={"Rp. "} />
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="p">Cashback</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="p" display="flex" justifyContent="end">
                        <NumericFormat value={cashback} displayType={"text"}
                          thousandSeparator="." decimalSeparator="," prefix={"Rp. "} />
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <hr style={{ height: '1px', background: 'black', border: "none" }}></hr>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="h6" fontWeight="bold">Total</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h6" fontWeight="bold" display="flex" justifyContent="end">
                        <NumericFormat value={totalPrice - discount - cashback} displayType={"text"}
                          thousandSeparator="." decimalSeparator="," prefix={"Rp. "} />
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <hr style={{ height: '1px', background: 'black', border: "none" }}></hr>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="p">Masukkan Kode Diskon</Typography>
                      <Grid container marginTop={2}>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            value={discountCode}
                            onChange={(e) => setDiscountCode(e.target.value)}
                            placeholder="Kode Diskon"
                            sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", border: ".5px solid black" }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button sx={{ 
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            borderTopRightRadius: "12px", 
                            borderBottomRightRadius: "12px",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "#FE8A01",
                            textTransform: "none",
                            height: "59px",
                            width: "100%",
                          }}>
                            Gunakan
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        style={
                          cartArr.filter((cart) => cart.checked).length <= 0
                            ? btnDisabledStyle
                            : btnCheckoutStyle
                        }
                        disabled={cartArr.filter((cart) => cart.checked).length <= 0}
                        onClick={handleOpenProceed}
                      >
                        Lanjut ke Checkout
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={styleModalDelete}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin menghapus produk ini dari keranjang?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#E50B0B", color: "white" }}
                onClick={deleteCart}
                disabled={loadingDelete}
              >
                Hapus
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseDelete}
                sx={{
                  ml: 2,
                  backgroundColor: "#FE8A01",
                  color: "white",
                }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openProceed}
        onClose={handleCloseProceed}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProceed}>
          <Box sx={styleModalDelete}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Apakah anda yakin ingin melakukan checkout?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#FE8A01", color: "white" }}
                onClick={proceedToPayment}
                disabled={loadingProceed}
              >
                Checkout
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseProceed}
                sx={{ ml: 2 }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
