export const fontSizeHeader = {
  fontSize: { xs: "1em", md: "2em" }
};

export const fontSizeSubHeader = {
  fontSize: { xs: "1em", md: "1.5em" }
}

export const fontSizeText = {
  fontSize: { xs: ".8em", md: "1.3em" }
};

export const fontSizeTextSmall = {
  fontSize: { xs: ".7em", md: "1em" }
};