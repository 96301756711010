import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import Navigation from "./navigation";
import CustomerService from "../services/customer/customerService";
import CartService from "../services/customer/cartService";
import { useAuth } from "../authContext";
import WishlistService from "../services/customer/wishlistService";
import Footer from "./components/footer/footer";
import noImage from "../assets/no-image.png";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AddIcon from '@mui/icons-material/Add';
import ItemCarousel from "./components/item/carousel";
import ShowMoreCard from "./components/item/showMoreCard";
import ItemCard from "./components/item/itemCard";
import { fontSizeText, fontSizeTextSmall } from "../helper/style";

const ItemDetailPage2 = () => {
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState(null);
  const [isBookmarked, setBookmarked] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [item, setItems] = useState(null);
  const [size, setSize] = useState("");
  const [variant, setVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [postCode, setPostCode] = useState("");
  const [OpenWishlistSnack, setopenWishlistSnack] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false);
  const [loadingCalculateFee, setLoadingCalculateFee] = useState(false);
  const [OpenSuccessSnack, setopenSuccessSnack] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const { auth } = useAuth();

  const btnStyle = {
    borderRadius: "12px",
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: 5,
    fontSize: "1em",
  };

  const btnStyleOutlined = {
    borderRadius: "12px",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    color: "black",
    padding: 5,
    fontSize: "1em",
  };

  const btnStyleOutlinedTop = {
    borderRadius: "12px",
    backgroundColor: "transparent",
    color: "black",
    padding: 5,
    fontSize: "1em",
  };

  const qtyBtnStyle = {
    width: "30px",
    height: "30px",
    color: "white",
    background: "black",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    cursor: "pointer",
    border: "none"
  }

  const actionWishlist = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/wishlist")}
      >
        Open Wishlist
      </Button>
    </>
  );

  const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/cart")}
      >
        Open Cart
      </Button>
    </>
  );

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const handleChangeSize = (event) => {
    setSize(event.target.value);
    
    const variant = item?.variantModels.find(v => v.id === event.target.value);
    setVariant(variant);

    if (quantity > variant.quantity) {
      setQuantity(variant.quantity)
    }
  };

  const handleBookmark = async () => {
    if (auth?.user) {
      setLoadingWishlist(true);
      if (isBookmarked) {
        await deleteWishlist();
      } else {
        await addWishlist();
      }
      setLoadingWishlist(false);
    } else {
      navigate("/login");
    }
  }

  const handleQuantityChange = (value) => {
    if (!value) {
      value = '1';
    }

    if (!isNaN(parseInt(value))) {
      if (parseInt(value) < 1) {
        value = '1';
      }
    }

    let number = value.replace(/\D/g,'');
    if (variant) {
      if (number > variant.quantity) {
        number = variant.quantity
      }
    }

    setQuantity(parseInt(number))
  }

  const handleAddQuantity = () => {
    let qty = quantity + 1;
    if (variant) {
      if (qty > variant.quantity) {
        qty = variant.quantity
      }
    }

    setQuantity(qty)
  }

  const handleMinusQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const validate = () => {
    let tempErrors = {};

    if (postCode.length !== 5) {
      tempErrors.postCode = "Kode pos harus berisi 5 digit";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const fetchItemDetail = async () => {
    try {
      const responseItem = await CustomerService.getItemDetail(id);
      setItems(responseItem.data);
      // setSize(responseItem.data.size)
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchSimilarItems() {
    if (item == null) {
      return;
    }

    try {
      const responseItem = await CustomerService.getListItemPageable(
        1, 8,
        '',
        null,
        item?.tagSecondary[0]?.id
      );
      setSimilarProduct(responseItem.data.itemList || []);
    } catch (error) {
      console.error(error);
    }
  }

  const getWishlistStatus = async () => {
    try {
      const response = await WishlistService.getWishlistStatus(auth?.user.id, id);
      if (response) {
        const status = response.data.status;
        setBookmarked(status)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const addWishlist = async () => {
    try {
      const response = await WishlistService.addWishlist(auth?.user.id, id);
      if (response) {
        setBookmarked(true)
        setopenWishlistSnack(true)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const deleteWishlist = async () => {
    try {
      const response = await WishlistService.deleteWishlist(auth?.user.id, id);
      if (response) {
        setBookmarked(false)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const addItem = async () => {
    if (!auth?.user) {
      navigate("/login");
      return;
    }

    if (!size) {
      setMsgError("Silahkan pilih ukuran terlebih dahulu");
      activateError();
      return;
    }

    const variant = item.variantModels?.find(v => v.id === size)
    if (quantity > variant.quantity) {
      setMsgError("Stok tidak mencukupi");
      activateError();
      return;
    }

    setLoadingAddItem(true);
    try {
      const response = await CartService.addToCart(auth?.user.id, id, quantity, size);
      if (response) {
        setopenSuccessSnack(true);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Tambah item gagal");
      activateError();
    } finally {
      setLoadingAddItem(false);
    }
  };

  const calculateDeliveryFee = async () => {
    if (!validate()) {
      return;
    }

    try {
      setLoadingCalculateFee(true);
      setDeliveryFee(null);
      const response = await CustomerService.getDeliveryFee(item.id, postCode);
      if (response) {
        const data = response.data;
        setDeliveryFee(data.deliveryFee);
      }
    } catch (error) {
      console.error(error);
      const response = error.response;
      if (response.request.status === 404) {
        setErrors({postCode: "Kode pos tidak ditemukan"});
      }
    } finally {
      setLoadingCalculateFee(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchItemDetail();
  }, [id]);

  useEffect(() => {
    fetchSimilarItems()
    getWishlistStatus()
  }, [item])

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ paddingBottom: "50px", minHeight: "80vh"}}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} columnSpacing={2}>
          <Grid item xs={6} md={7} sx={{ display: {xs: "none", md: "block"} }}>
            <ImageList variant="masonry" cols={item?.images?.length > 1 ? 2 : 1} gap={8}>
              {item?.images?.map((image) => (
                <ImageListItem key={image}>
                  <img
                    src={`${imageUrl}/${image}`}
                    alt={image.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          <Grid item xs={6} md={7} sx={{ display: {xs: "block", sm: "block", md: "none"} }}>
            <ImageList variant="masonry" cols={1} gap={8}>
              {item?.images?.map((image) => (
                <ImageListItem key={image}>
                  <img
                    src={`${imageUrl}/${image}`}
                    alt={image.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          <Grid item xs={6} md={5}>
            <Grid container marginTop="16px">
              <Grid item xs={8} md={6} display="flex" alignItems="center">
                {
                  item?.brand && (
                    <Box>
                      <img 
                        src={item?.brand.images == null ? noImage : `${imageUrl}${item.brand.images[0]}`}
                        alt={item?.brand.title}
                        style={{ width: "80%", objectFit: "contain" }}
                      />
                    </Box>
                  )
                }
              </Grid>
              <Grid item xs={4} md={6} textAlign="end" display="flex" justifyContent="end" alignItems="center">
                <Button
                  style={{ ...btnStyleOutlinedTop, minWidth: "unset" }}
                  onClick={handleBookmark}
                  disabled={loadingWishlist}
                  sx={{ display: {xs: "block", sm: "block", md: "none"} }}
                >
                  {isBookmarked ? <BookmarkIcon sx={{ fontSize: 30 }} /> : <BookmarkBorderIcon sx={{ fontSize: 30 }} />}
                </Button>
              </Grid>
            </Grid>
            <Typography variant="h5" fontWeight="600" sx={{ fontSize: { xs: "1em", md: "1.5em" } }}>{item?.name}</Typography>
            <Typography variant="h5" sx={{ fontSize: { xs: "1em", md: "1.5em" } }}>
              <NumericFormat
                value={item?.defaultPrice}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp. "}
              />
            </Typography>

            <Grid container marginTop="20px" columnSpacing={2}>
              <Grid item xs={12} md={9} marginBottom={1}>
                <InputLabel id="label-size">Size</InputLabel>
                <Select
                  fullWidth
                  labelId="label-size"
                  id="select-size"
                  value={size}
                  onChange={handleChangeSize}
                  sx={{  height: "30px", fontSize: ".8em"  }}
                >
                  {/* <MenuItem value={item?.size}>{item?.size}</MenuItem> */}
                  {
                    item?.variantModels?.map( variant => (
                      <MenuItem key={variant.id} value={variant.id}>
                        {variant.size}
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  variant && (
                    <Typography sx={{ ...fontSizeTextSmall }}>Stok: {variant.quantity}</Typography>
                  )
                }
              </Grid>
              <Grid item xs={12} md={3} marginBottom={1}>
                <InputLabel id="label-quantity">Jumlah</InputLabel>
                <Box display="flex" alignItems="center" gap="5px">
                  <button style={qtyBtnStyle} onClick={handleMinusQuantity}>-</button>
                  <TextField
                    sx={{ width: "50px", textAlign: "center"  }}
                    autoComplete="off"
                    value={quantity}
                    min="1"
                    onChange={(e) => handleQuantityChange(e.target.value)}
                    InputProps={{ 
                      style: { height: "30px", fontSize: ".8em", padding: "2px", textAlign: "center" }
                    }}
                  />
                  <button style={qtyBtnStyle} onClick={handleAddQuantity}>+</button>
                </Box>
                {/* <TextField
                  fullWidth
                  type="number"
                  autoComplete="off"
                  value={quantity}
                  min="1"
                  onChange={(e) => handleQuantityChange(e.target.value)}
                /> */}
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid item xs={12} md={10}>
                <Button style={btnStyle} endIcon={<AddIcon />} onClick={addItem}>
                  <Typography sx={{ fontSize: { xs: ".6em", md: "1em" } }}>Tambahkan ke Keranjang</Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={2}>
                <Button
                  style={{ ...btnStyleOutlined, minWidth: "unset" }}
                  onClick={handleBookmark}
                  disabled={loadingWishlist}
                  sx={{ display: {xs: "none", md: "block"} }}
                >
                  {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                </Button>
              </Grid>
            </Grid>

            <Box marginTop={1}>
              <Accordion defaultExpanded sx={{ background: "transparent", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel-pengiriman-content"
                  id="panel-pengiriman-header"
                  sx={{ borderTop: "1px solid black", padding: "5px", height: "40px", minHeight: "unset"  }}
                  style={{ minHeight: "unset !important" }}
                >
                  <Typography variant="h4" sx={{ fontSize: { xs: "1em", md: "1.3em" }}}>Pengiriman</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "5px" }}>
                  <Typography variant="p" fontWeight="bold" color="#8E8E8E" sx={{ fontSize: { xs: ".6em", md: "1em" } }}>
                    Masukkan kode pos untuk mengecek biaya pengiriman:
                  </Typography>
                  <Grid container marginTop={1} columnSpacing={2}>
                    <Grid item xs={12} md={8} marginBottom={1}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                        placeholder="Masukkan kode pos"
                        error={errors?.postCode}
                        helperText={errors?.postCode}
                        FormHelperTextProps={{ sx: { color: "red" } }}
                        // sx={{ fontSize: ".8em" }}
                        InputProps={{ 
                          style: { height: "30px", fontSize: ".9em", padding: "2px" }
                        }}
                        // sx={{  height: "30px", fontSize: ".8em"  }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} marginBottom={1} display="flex" alignItems="center">
                      <Button
                        style={{ ...btnStyle, backgroundColor: "#8E8E8E" }}
                        disabled={loadingCalculateFee}
                        onClick={calculateDeliveryFee}
                      >
                        <Typography sx={{ fontSize: { xs: ".6em", md: "1em" } }}>{loadingCalculateFee ? "Loading" : "Cek"}</Typography>
                      </Button>
                    </Grid>
                    {
                      deliveryFee !== null && (
                        <Grid item xs={12} marginTop={1}>
                          <Typography sx={{ fontSize: { xs: ".6em", md: "1em" } }}>
                            Ongkos Kirim:&nbsp;
                            <NumericFormat
                              value={deliveryFee}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix={"Rp. "}
                            />
                          </Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded sx={{ background: "transparent", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel-detail-content"
                  id="panel-detail-header"
                  sx={{ borderTop: "1px solid black", padding: "5px", height: "40px", minHeight: "unset"}}
                  style={{ minHeight: "unset !important" }}
                >
                  <Typography variant="h4" sx={{ fontSize: { xs: "1em", md: "1.3em" }}}>Detail</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "5px"  }}>
                  <Typography sx={{ fontSize: { xs: ".6em", md: "1em" } }}>{item?.description}</Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel-detail-content"
                  id="panel-detail-header"
                  sx={{ borderTop: "1px solid black", paddingBottom: "5px" }}
                >
                  <Typography variant="h4">Size & Fit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque id, tincidunt leo quam mus pharetra dignissim venenatis rhoncus quis litora, feugiat potenti nibh libero aptent sodales malesuada tortor. Elementum sapien neque cursus et scelerisque blandit pretium, ultricies dis ligula mauris natoque sed ante lobortis, nec cum primis volutpat non platea.
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel-detail-content"
                  id="panel-detail-header"
                  sx={{ borderTop: "1px solid black", paddingBottom: "5px" }}
                >
                  <Typography variant="h4">Bagikan</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque id, tincidunt leo quam mus pharetra dignissim venenatis rhoncus quis litora, feugiat potenti nibh libero aptent sodales malesuada tortor. Elementum sapien neque cursus et scelerisque blandit pretium, ultricies dis ligula mauris natoque sed ante lobortis, nec cum primis volutpat non platea.
                </AccordionDetails>
              </Accordion> */}
            </Box>

          </Grid>
        </Grid>

        <ItemCarousel title="Anda Mungkin Juga Suka">
          {
            similarProduct.map(item => (
              <ItemCard key={item.id} item={item} />
            ))
          }
          {
            similarProduct.length > 0 && (
              <ShowMoreCard onClick={() => navigate(`/items/tag/${item?.tagSecondary[0]?.id}`)} />
            )
          }
        </ItemCarousel>

      </Container>
    
      <Footer />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={OpenSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setopenSuccessSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={action}
          message="Berhasil menambahkan ke keranjang"/>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={OpenWishlistSnack}
        autoHideDuration={3000}
        onClose={() => setopenWishlistSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={actionWishlist}
          message="Berhasil menambahkan ke wishlist"/>
      </Snackbar>


    </>
  )
};

export default ItemDetailPage2;
